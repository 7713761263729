import React from 'react'
import { Link } from 'react-router-dom'

const Footer1 = () => {
  return (
    <footer
    className="footer-wrapper footer-layout3"
    data-bg-src="assets/img/bg/footer_bg_2.jpg"
  >
    <div className="footer-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-3">
            <div className="footer-logo">
              <Link className="icon-masking" to="/">
                <span
                  data-mask-src="assets/img/logo-white.svg"
                  className="mask-icon"
                />
                <img src="assets/img/logo-white.svg" alt="Webteck" />
              </Link>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h3 className="newsletter-title">News Subscription</h3>
                <p className="newsletter-text">
                  Get Latest Deals from Waker’s Inbox &amp; Subscribe Now
                </p>
              </div>
              <form className="newsletter-form">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email Address"
                    required=""
                  />{" "}
                  <i className="fal fa-envelope" />
                </div>
                <button type="submit" className="th-btn style3">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xxl-3 col-xl-4">
            <div className="widget footer-widget">
              <h3 className="widget_title">About Company</h3>
              <div className="th-widget-about">
                <p className="about-text">
                  Professionally redefine transparent ROI through low-risk
                  high-yield imperatives. Progressively create empowered. cost
                  effective users via team driven.
                </p>
                <div className="th-social">
                  <Link to="https://www.facebook.com/">
                    <i className="fab fa-facebook-f" />
                  </Link>{" "}
                  <Link to="https://www.twitter.com/">
                    <i className="fab fa-twitter" />
                  </Link>{" "}
                  <Link to="https://www.linkedin.com/">
                    <i className="fab fa-linkedin-in" />
                  </Link>{" "}
                  <Link to="https://www.whatsapp.com/">
                    <i className="fab fa-whatsapp" />
                  </Link>{" "}
                  <Link to="https://www.youtube.com/">
                    <i className="fab fa-youtube" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">Quick Links</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="../team">Team</Link>
                  </li>
                  <li>
                    <Link to="../faq">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/project">Projects</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">IT SERVICES</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/service-details">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Business Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Product Management</Link>
                  </li>
                  <li>
                    <Link to="/service-details">UI/UX Design</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Cloud services</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget footer-widget">
              <h3 className="widget_title">Recent Posts</h3>
              <div className="recent-post-wrap">
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-1.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Unsatiable entreaties may collecting Power.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        21 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-2.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Regional Manager limited time management.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        22 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <p className="copyright-text">
              Copyright <i className="fal fa-copyright" /> 2024{" "}
              <Link to="https://themeforest.net/user/themeholy">Themeholy</Link>.
              All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-lg-end text-center">
            <div className="footer-links">
              <ul>
                <li>
                  <Link to="/about">Digital Invoice</Link>
                </li>
                <li>
                  <Link to="/about">Insights</Link>
                </li>
                <li>
                  <Link to="/about">Reimbursements</Link>
                </li>
                <li>
                  <Link to="/about">Virtual Assistant</Link>
                </li>
                <li>
                  <Link to="/about">Artificial Intelligence</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer1