import React from "react";
import TabTitle from "../Utiles/FunctionTitle";
import { Link } from 'react-router-dom'
import Navbar from "../Common/Navbar";
import Footer1 from "../Common/Footer1";

const WebDevelopment = () => {
  TabTitle('Web Development - HRHK Technology')

  return (
    <React.Fragment>
      <div className="th-hero-wrapper hero-3" id="hero">
        <div className="hero-img">
          <img src="assets/img/hero/hero_img_3_1.png" alt="Hero Image" />
          <div className="shape-blur" />
        </div>
        <div className="hero-img-phone">
          <img src="assets/img/hero/hero_img_3_phone.png" alt="Hero Image" />
          <div className="shape-blur" />
        </div>
        <div className="container">
          <div className="hero-style3">
            <span className="hero-subtitle">SECURE &amp; IT SERVICES</span>
            <h1 className="hero-title">HRHK Best</h1>
            <h1 className="hero-title">IT Solution 2024</h1>
            <p className="hero-text">
              Energistically harness ubiquitous imperatives without state of the
              art collaboration and idea-sharing. Monotonectally parallel task
              cross-unit experiences and front-end.
            </p>
            <div className="btn-group">
              <Link to="/about" className="th-btn">
                ABOUT US
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
        <div className="hero-social">
          <Link to="https://www.facebook.com/">Facebook</Link>
          <Link to="https://www.twitter.com/">Twitter</Link>
          <Link to="https://www.instagram.com/">Instagram</Link>
          <Link to="https://www.linkedin.com/">Linkedin</Link>
        </div>
        <div className="hero-shape1">
          <img src="assets/img/hero/hero_shape_3_1.png" alt="shape" />
        </div>
      </div>
      <div className="space" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 mb-30 mb-xl-0">
              <div className="img-box3">
                <div className="img1">
                  <img src="assets/img/normal/about_2_1.jpg" alt="About" />
                </div>
                <div className="img2">
                  <img src="assets/img/normal/about_2_2.jpg" alt="About" />
                </div>
                <div className="img3">
                  <img src="assets/img/normal/about_2_3.jpg" alt="About" />
                </div>
                <div className="shape1">
                  <img src="assets/img/normal/about_2_shape.png" alt="About" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="title-area mb-35">
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  About HRHK IT SOLUTIONS
                </span>
                <h2 className="sec-title">
                  Preparing For Your Business Provide Best
                  <span className="text-theme fw-normal">IT Solution</span>
                </h2>
              </div>
              <p className="mt-n2 mb-25">
                Quickly re-engineer intuitive e-services whereas compelling
                niches. Professionally syndicate strategic e-commerce without
                covalent leadership skills. Globally customize equity invested
                imperatives.
              </p>
              <div className="checklist style3 mb-40">
                <ul>
                  <li>
                    <i className="fa-solid fa-square-check" /> Dramatically
                    re-engineer value added IT systems via mission
                  </li>
                  <li>
                    <i className="fa-solid fa-square-check" /> Website &amp;
                    Mobile application design &amp; Development
                  </li>
                  <li>
                    <i className="fa-solid fa-square-check" /> Professional User
                    Experince &amp; Interface researching
                  </li>
                </ul>
              </div>
              <Link to="/about" className="th-btn">
                DISCOVER MORE
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
        <div
          className="shape-mockup d-none d-lg-block"
          data-bottom="0%"
          data-right="0%"
        >
          <div className="particle-2 small" id="particle-1" />
        </div>
      </div>
      <div
        className="bg-theme space-extra"
        data-bg-src="assets/img/bg/counter_bg_1.png"
      >
        <div className="container py-2">
          <div className="row gy-40 justify-content-between">
            <div className="col-6 col-lg-auto">
              <div className="counter-card">
                <div className="counter-card_icon">
                  <img src="assets/img/icon/counter_1_1.svg" alt="Icon" />
                </div>
                <div className="media-body">
                  <h2 className="counter-card_number">
                    <span className="counter-number">986</span>+
                  </h2>
                  <p className="counter-card_text">Finished Project</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <div className="counter-card">
                <div className="counter-card_icon">
                  <img src="assets/img/icon/counter_1_2.svg" alt="Icon" />
                </div>
                <div className="media-body">
                  <h2 className="counter-card_number">
                    <span className="counter-number">896</span>+
                  </h2>
                  <p className="counter-card_text">Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <div className="counter-card">
                <div className="counter-card_icon">
                  <img src="assets/img/icon/counter_1_3.svg" alt="Icon" />
                </div>
                <div className="media-body">
                  <h2 className="counter-card_number">
                    <span className="counter-number">396</span>+
                  </h2>
                  <p className="counter-card_text">Skilled Experts</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <div className="counter-card">
                <div className="counter-card_icon">
                  <img src="assets/img/icon/counter_1_4.svg" alt="Icon" />
                </div>
                <div className="media-body">
                  <h2 className="counter-card_number">
                    <span className="counter-number">496</span>+
                  </h2>
                  <p className="counter-card_text">Honorable Awards</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-smoke space">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="title-area mb-35 text-center text-xl-start">
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  GREAT IT SKILLS
                </span>
                <h2 className="sec-title">
                  More Than 24+ Years Experience We Provide
                  <span className="text-theme fw-normal">IT Services</span>
                </h2>
              </div>
              <p className="mt-n2 mb-25 text-center text-xl-start">
                Collaboratively envisioneer user friendly supply chains and
                cross unit imperative. Authoritativel fabricate competitive
                resource and holistic. Holisticly restore real time resources
                whereas standardized networks.
              </p>
              <div className="pe-xxl-4">
                <div className="skill-feature">
                  <h3 className="skill-feature_title">Business Strategy</h3>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: "85%" }}>
                      <div className="progress-value">85%</div>
                    </div>
                  </div>
                </div>
                <div className="skill-feature">
                  <h3 className="skill-feature_title">Softwer Development</h3>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: "95%" }}>
                      <div className="progress-value">95%</div>
                    </div>
                  </div>
                </div>
                <div className="skill-feature">
                  <h3 className="skill-feature_title">Cyber Security</h3>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: "90%" }}>
                      <div className="progress-value">90%</div>
                    </div>
                  </div>
                </div>
                <div className="skill-feature">
                  <h3 className="skill-feature_title">
                    Artificial Intelligence
                  </h3>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: "80%" }}>
                      <div className="progress-value">80%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 mt-40 mt-xl-0">
              <div className="video-box1">
                <img
                  className="tilt-active"
                  src="assets/img/normal/video_shape_1.png"
                  alt="Video"
                />
                <Link
                  to="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                  className="play-btn popup-video"
                >
                  <i className="fas fa-play" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-mockup" data-top="0%" data-left="0%">
          <img src="assets/img/shape/tech_shape_3.png" alt="shape" />
        </div>
        <div className="shape-mockup" data-bottom="0%" data-right="0%">
          <img src="assets/img/shape/tech_shape_4.png" alt="shape" />
        </div>
      </div>
      <section className="space" id="service-sec">
        <div className="container">
          <div className="row justify-content-lg-between justify-content-center align-items-center">
            <div className="col-lg-6 col-sm-9 pe-xl-5">
              <div className="title-area text-center text-lg-start">
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  WHAT WE DO
                </span>
                <h2 className="sec-title">
                  We Provide Exclusive Service For
                  <span className="text-theme fw-normal">Your Business</span>
                </h2>
              </div>
            </div>
            <div className="col-auto">
              <div className="sec-btn">
                <Link to="/service" className="th-btn">
                  VIEW ALL SERVICES
                  <i className="fa-regular fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="slider-area">
            <div
              className="swiper th-slider has-shadow"
              id="projectSlider2"
              data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_1.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_1.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Web Development</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_2.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_2.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">UI/UX Design</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_3.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_3.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Digital Marketing</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_4.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_4.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Business Analysis</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_5.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_5.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Software Services</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_1.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_1.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Web Development</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_2.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_2.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">UI/UX Design</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_3.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_3.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Digital Marketing</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_4.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_4.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Business Analysis</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="service-box">
                    <div className="service-box_img">
                      <img
                        src="assets/img/service/service_box_5.jpg"
                        alt="Icon"
                      />
                    </div>
                    <div className="service-box_content">
                      <div className="service-box_icon">
                        <img
                          src="assets/img/icon/service_box_5.svg"
                          alt="Icon"
                        />
                      </div>
                      <h3 className="box-title">
                        <Link to="/service-details">Software Services</Link>
                      </h3>
                      <p className="service-box_text">
                        Intrinsicly redefine competitive e-business before
                        adaptive.
                      </p>
                      <Link to="/service-details" className="link-btn">
                        Read More
                        <i className="fas fa-arrow-right ms-2" />
                      </Link>
                      <div className="bg-shape">
                        <img src="assets/img/bg/service_box_bg.png" alt="bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-smoke space"
        data-bg-src="assets/img/bg/team_bg_2.png"
      >
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              GREAT TEAM
            </span>
            <h2 className="sec-title">
              See Our Skilled Expert
              <span className="text-theme fw-normal">Team</span>
            </h2>
          </div>
          <div className="slider-area">
            <div
              className="swiper th-slider has-shadow"
              id="teamSlider2"
              data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_1.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p1" />
                      <h3 className="box-title">
                        <Link to="/team-details">Rayan Athels</Link>
                      </h3>
                      <span className="team-desig">Founder &amp; CEO</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_2.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p2" />
                      <h3 className="box-title">
                        <Link to="/team-details">Alex Furnandes</Link>
                      </h3>
                      <span className="team-desig">Project Manager</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_3.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p3" />
                      <h3 className="box-title">
                        <Link to="/team-details">Mary Crispy</Link>
                      </h3>
                      <span className="team-desig">Cheif Expert</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_4.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p4" />
                      <h3 className="box-title">
                        <Link to="/team-details">Henry Joshep</Link>
                      </h3>
                      <span className="team-desig">Product Manager</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_5.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p5" />
                      <h3 className="box-title">
                        <Link to="/team-details">Sanjida Carlose</Link>
                      </h3>
                      <span className="team-desig">IT Consultant</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_1.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p1" />
                      <h3 className="box-title">
                        <Link to="/team-details">Rayan Athels</Link>
                      </h3>
                      <span className="team-desig">Founder &amp; CEO</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_2.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p2" />
                      <h3 className="box-title">
                        <Link to="/team-details">Alex Furnandes</Link>
                      </h3>
                      <span className="team-desig">Project Manager</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_3.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p3" />
                      <h3 className="box-title">
                        <Link to="/team-details">Mary Crispy</Link>
                      </h3>
                      <span className="team-desig">Cheif Expert</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_4.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p4" />
                      <h3 className="box-title">
                        <Link to="/team-details">Henry Joshep</Link>
                      </h3>
                      <span className="team-desig">Product Manager</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="th-team team-box">
                    <div className="team-img">
                      <img src="assets/img/team/team_2_5.jpg" alt="Team" />
                      <div className="team-social">
                        <div className="play-btn">
                          <i className="far fa-plus" />
                        </div>
                        <div className="th-social">
                          <Link target="_blank" to="https://facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </Link>
                          <Link target="_blank" to="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </Link>
                          <Link target="_blank" to="https://instagram.com/">
                            <i className="fab fa-instagram" />
                          </Link>
                          <Link target="_blank" to="https://linkedin.com/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="team-content">
                      <div className="box-particle" id="team-p5" />
                      <h3 className="box-title">
                        <Link to="/team-details">Sanjida Carlose</Link>
                      </h3>
                      <span className="team-desig">IT Consultant</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              data-slider-prev="#teamSlider2"
              className="slider-arrow style3 slider-prev"
            >
              <i className="far fa-arrow-left" />
            </button>
            <button
              data-slider-next="#teamSlider2"
              className="slider-arrow style3 slider-next"
            >
              <i className="far fa-arrow-right" />
            </button>
          </div>
        </div>
      </section>
      <section
        className="bg-auto space"
        data-bg-src="assets/img/bg/testi_bg_2.png"
      >
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              CUSTOMER FEEDBACK
            </span>
            <h2 className="sec-title">
              What Happy Clients Says
              <br />
              <span className="text-theme fw-normal">About Us?</span>
            </h2>
          </div>
          <div className="slider-area">
            <div
              className="swiper th-slider has-shadow"
              id="testiSlider2"
              data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"1"},"1200":{"slidesPerView":"2"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_1.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">David Farnandes</h3>
                      <p className="testi-box_desig">CEO at Anaton</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_2.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">Jackline Techie</h3>
                      <p className="testi-box_desig">CEO at Kormola</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_3.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">Abraham Khalil</h3>
                      <p className="testi-box_desig">CEO at Rimasu</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_1.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">David Farnandes</h3>
                      <p className="testi-box_desig">CEO at Anaton</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_2.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">Jackline Techie</h3>
                      <p className="testi-box_desig">CEO at Kormola</p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testi-box">
                    <div className="testi-box_img">
                      <img
                        src="assets/img/testimonial/testi_2_3.jpg"
                        alt="Avater"
                      />
                      <div className="testi-box_quote">
                        <img
                          src="assets/img/icon/quote_left_2.svg"
                          alt="quote"
                        />
                      </div>
                    </div>
                    <div className="testi-box_content">
                      <p className="testi-box_text">
                        Objectively visualize error-free technology for B2B
                        alignment. Monotonectally harness an expanded array of
                        models via effective collaboration.
                      </p>
                      <div className="testi-box_review">
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                        <i className="fa-solid fa-star-sharp" />
                      </div>
                      <h3 className="box-title">Abraham Khalil</h3>
                      <p className="testi-box_desig">CEO at Rimasu</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              data-slider-prev="#testiSlider2"
              className="slider-arrow style3 slider-prev"
            >
              <i className="far fa-arrow-left" />
            </button>
            <button
              data-slider-next="#testiSlider2"
              className="slider-arrow style3 slider-next"
            >
              <i className="far fa-arrow-right" />
            </button>
          </div>
        </div>
        <div
          className="shape-mockup moving d-none d-xl-block"
          data-bottom="0%"
          data-left="10%"
        >
          <img src="assets/img/shape/line_1.png" alt="shape" />
        </div>
        <div
          className="shape-mockup jump d-none d-xl-block"
          data-top="20%"
          data-right="2%"
        >
          <img src="assets/img/shape/line_2.png" alt="shape" />
        </div>
      </section>
      <section className="position-relative space">
        <div className="th-bg-img" data-bg-src="assets/img/bg/cta_bg_2.jpg">
          <img src="assets/img/bg/bg_overlay_1.png" alt="overlay" />
        </div>
        <div className="container z-index-common">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9 text-center">
              <div className="title-area mb-35">
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  CONTACT US
                </span>
                <h2 className="sec-title text-white">
                  Need Any Kind Of IT Solution For
                  <span className="text-theme fw-normal">Your Business?</span>
                </h2>
              </div>
              <Link to="/contact" className="th-btn style3">
                Get In Touch
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="space">
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              OUR PRICING
            </span>
            <h2 className="sec-title">
              Popular Pricing{" "}
              <span className="text-theme fw-normal">Package</span>
            </h2>
          </div>
          <div className="row gy-4 justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="price-card">
                <div className="price-card_top">
                  <h3 className="price-card_title">Silver Package</h3>
                  <p className="price-card_text">
                    Pricing plan for IT Solution company
                  </p>
                  <h4 className="price-card_price">
                    $199.00 <span className="duration">/Per Month</span>
                  </h4>
                  <div className="particle">
                    <div className="price-particle" id="price-p1" />
                  </div>
                </div>
                <div className="price-card_content">
                  <div className="checklist">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" /> 30 Days Trial
                        Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Unlimited Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Multi-Language
                        Content
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-check" /> Data backup and
                        recovery
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-check" /> Synced To Cloud
                        Database
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn">
                    PURCHASE NOW
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-card">
                <div className="price-card_top">
                  <h3 className="price-card_title">Gold Package</h3>
                  <p className="price-card_text">
                    Pricing plan for IT Solution company
                  </p>
                  <h4 className="price-card_price">
                    $299.00 <span className="duration">/Per Month</span>
                  </h4>
                  <div className="particle">
                    <div className="price-particle" id="price-p2" />
                  </div>
                </div>
                <div className="price-card_content">
                  <div className="checklist">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" /> 30 Days Trial
                        Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Unlimited Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Multi-Language
                        Content
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Data backup and
                        recovery
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-check" /> Synced To Cloud
                        Database
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn">
                    PURCHASE NOW
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-card">
                <div className="price-card_top">
                  <h3 className="price-card_title">Platinum Package</h3>
                  <p className="price-card_text">
                    Pricing plan for IT Solution company
                  </p>
                  <h4 className="price-card_price">
                    $599.00 <span className="duration">/Per Month</span>
                  </h4>
                  <div className="particle">
                    <div className="price-particle" id="price-p3" />
                  </div>
                </div>
                <div className="price-card_content">
                  <div className="checklist">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" /> 30 Days Trial
                        Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Unlimited Features
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Multi-Language
                        Content
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Data backup and
                        recovery
                      </li>
                      <li>
                        <i className="fas fa-circle-check" /> Synced To Cloud
                        Database
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn">
                    PURCHASE NOW
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-mockup" data-top={0} data-right={0}>
          <img src="assets/img/shape/tech_shape_1.png" alt="shape" />
        </div>
        <div className="shape-mockup" data-top="0%" data-left="0%">
          <img src="assets/img/shape/square_1.png" alt="shape" />
        </div>
      </section>
      <div
        className="brand-sec1"
        data-pos-for="#faq-sec"
        data-sec-pos="bottom-half"
      >
        <div className="container py-5">
          <div className="slider-area text-center">
            <div
              className="swiper th-slider"
              id="brandSlider2"
              data-slider-options='{"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"},"1400":{"slidesPerView":"5"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_1.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_2.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_3.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_4.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_5.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_6.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_1.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_2.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_3.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_4.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_5.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand-box py-20">
                    <img
                      src="assets/img/brand/brand_2_6.png"
                      alt="Brand Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              data-slider-prev="#brandSlider2"
              className="slider-arrow style3 slider-prev"
            >
              <i className="far fa-arrow-left" />
            </button>
            <button
              data-slider-next="#brandSlider2"
              className="slider-arrow style3 slider-next"
            >
              <i className="far fa-arrow-right" />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-smoke overflow-hidden space" id="faq-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="title-area text-center text-xl-start">
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  Frequently Ask Question
                </span>
                <h2 className="sec-title">
                  Talk To About Any
                  <span className="text-theme fw-normal">Question?</span>
                </h2>
              </div>
              <div className="accordion-area accordion" id="faqAccordion">
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-1"
                      aria-expanded="false"
                      aria-controls="collapse-1"
                    >
                      Where can I get analytics help?
                    </button>
                  </div>
                  <div
                    id="collapse-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="collapse-item-1"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Dramatically disseminate real-time portals rather than
                        top-line action items. Uniquely provide access to
                        low-risk high-yield products without dynamic products.
                        Progressively re-engineer low-risk high-yield ideas
                        rather than emerging alignments.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card active">
                  <div className="accordion-header" id="collapse-item-2">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-2"
                      aria-expanded="true"
                      aria-controls="collapse-2"
                    >
                      How long should a business plan be?
                    </button>
                  </div>
                  <div
                    id="collapse-2"
                    className="accordion-collapse collapse show"
                    aria-labelledby="collapse-item-2"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Dramatically disseminate real-time portals rather than
                        top-line action items. Uniquely provide access to
                        low-risk high-yield products without dynamic products.
                        Progressively re-engineer low-risk high-yield ideas
                        rather than emerging alignments.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      Do I need a business plan?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Dramatically disseminate real-time portals rather than
                        top-line action items. Uniquely provide access to
                        low-risk high-yield products without dynamic products.
                        Progressively re-engineer low-risk high-yield ideas
                        rather than emerging alignments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 mt-35 mt-xl-0">
              <div className="faq-img tilt-active">
                <div className="img-shape icon-masking">
                  <span
                    className="mask-icon"
                    data-mask-src="assets/img/normal/about_3_1-shape.png"
                  />
                  <img src="assets/img/normal/about_3_1-shape.png" alt="img" />
                </div>
                <img src="assets/img/normal/faq_1_1.png" alt="Faq" />
              </div>
            </div>
          </div>
        </div>
        <div className="shape-mockup jump" data-bottom="0%" data-left="0%">
          <img src="assets/img/shape/tech_shape_5.png" alt="shape" />
        </div>
      </div>
      <section
        className="bg-top-right overflow-hidden space"
        id="blog-sec"
        data-bg-src="assets/img/bg/blog_bg_1.png"
      >
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              NEWS &amp; ARTICLES
            </span>
            <h2 className="sec-title">
              Get Every Single Update
              <span className="text-theme fw-normal">Blog</span>
            </h2>
          </div>
          <div className="slider-area">
            <div
              className="swiper th-slider has-shadow"
              id="blogSlider2"
              data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_1.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          15 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />2 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Unsatiable entreaties may collecting Power.
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_2.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          16 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />3 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Regional Manager &amp; limited time management.
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_3.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          17 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />2 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          What’s the Holding Back the It Solution Industry?
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_4.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          19 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />4 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Latin derived from Cicero's 1st-century BC
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_1.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          15 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />2 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Unsatiable entreaties may collecting Power.
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_2.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          16 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />3 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Regional Manager &amp; limited time management.
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_3.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          17 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />2 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          What’s the Holding Back the It Solution Industry?
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="assets/img/blog/blog_1_4.jpg"
                        alt="blog image"
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link to="/blog">
                          <i className="fal fa-calendar-days" />
                          19 Jan, 2024
                        </Link>
                        <Link to="/blog">
                          <i className="fal fa-comments" />4 Comments
                        </Link>
                      </div>
                      <h3 className="box-title">
                        <Link to="/blog-details">
                          Latin derived from Cicero's 1st-century BC
                        </Link>
                      </h3>
                      <p className="blog-text">
                        Progressively plagiarize quality metrics for impactful
                        data. Assertively. Holisticly leverage existing
                        magnetic.
                      </p>
                      <div className="blog-bottom">
                        <Link to="/blog" className="author">
                          <img
                            src="assets/img/blog/author-1-1.png"
                            alt="avater"
                          />
                          By Themeholy
                        </Link>
                        <Link to="/blog-details" className="line-btn">
                          Read More
                          <i className="fas fa-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              data-slider-prev="#blogSlider2"
              className="slider-arrow style3 slider-prev"
            >
              <i className="far fa-arrow-left" />
            </button>
            <button
              data-slider-next="#blogSlider2"
              className="slider-arrow style3 slider-next"
            >
              <i className="far fa-arrow-right" />
            </button>
          </div>
        </div>
        <div className="shape-mockup" data-bottom={0} data-left={0}>
          <div className="particle-2 small" id="particle-4" />
        </div>
      </section>
      <Footer1 />
      <div className="scroll-top">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "307.919",
            }}
          />
        </svg>
      </div>
      </React.Fragment>
  );
};

export default WebDevelopment;
