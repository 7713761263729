import React, { useState } from "react";
import TabTitle from "../Utiles/FunctionTitle";
import { Link } from 'react-router-dom'
import Footer1 from "../Common/Footer1";

const BlogDetails = () => {
 TabTitle("Webteck - Technology & IT Solutions HTML Template - Blog Details");
  return (
    <React.Fragment>
          <div
            className="breadcumb-wrapper"
            data-bg-src="assets/img/bg/breadcumb-bg.jpg"
          >
            <div className="container">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Blog Details</h1>
                <ul className="breadcumb-menu">
                  <li>
                    <Link to="index">Home</Link>
                  </li>
                  <li>Blog Details</li>
                </ul>
              </div>
            </div>
          </div>
          <section className="th-blog-wrapper blog-details space-top space-extra-bottom">
            <div className="container">
              <div className="row">
                <div className="col-xxl-8 col-lg-7">
                  <div className="th-blog blog-single">
                    <div className="blog-img">
                      <img src="assets/img/blog/blog-s-1-1.jpg" alt="Blog Image" />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link className="author" to="/blog">
                          <img src="assets/img/blog/author-1-1.png" alt="avater" />
                          By Themeholy
                        </Link>
                        <Link to="/blog">
                          <i className="fa-light fa-calendar-days" />
                          21 June, 2024
                        </Link>
                        <Link to="/blog-details">
                          <i className="fa-regular fa-comments" />
                          Comments (3)
                        </Link>
                      </div>
                      <h2 className="blog-title">
                        Unsatiable entreaties may collecting Power.
                      </h2>
                      <p>
                        Rapidiously repurpose leading edge growth strategies
                        with just in time web readiness service Objectively
                        communicate timely meta services for synergistic
                        initiatives functionalities.
                      </p>
                      <p>
                        Uniquely pursue emerging experiences before emerging
                        content. Efficiently underwhelm customer directed total
                        linkage after B2C synergy. Dynamically simplify superior
                        human capital whereas efficient infrastructures.
                        Authoritatively generate e-business web-readiness after
                        wireless outsourcing. Seamlessly evisculate visionary
                        scenarios for resource maximizing mindshare.
                      </p>
                      <p>
                        Assertively recaptiualize interdependent alignments via
                        backend leadership skills. Monotonectally formulate
                        focused quality vectors whereas proactive infomediaries.
                        Energistically utilize ethical initiatives without
                        impactful applications. Authoritatively coordinate
                        seamless e-services and user friendly information.
                        Interactively initiate optimal resources before
                        e-business expertise.
                      </p>
                      <blockquote>
                        <p>
                          “IT IS A LONG FACT THAT A READER WILL BE DISTRACTED BY
                          THE READABLE CONTENT OF A PAGE WHEN LOOKING AT ITS
                          LAYOUT.”
                        </p>
                        <cite>Md Sumon Mia</cite>
                      </blockquote>
                      <p>
                        Appropriately mesh standards compliant communities
                        vis-a-vis client-centric channels. Seamlessly reinvent
                        open-source data via bricks-and-clicks bandwidth.
                        Globally leverage other's inexpensive technologies
                        vis-a-vis user friendly systems. Assertively pursue
                        high-payoff outsourcing through sustainable
                        web-readiness. Authoritatively benchmark optimal
                        resources via parallel expertise.
                      </p>
                      <p>
                        Uniquely seize open-source synergy without leveraged
                        functionalities. Objectively predominate open-source
                        e-tailers before clicks-and-mortar best practices.
                        Distinctively recaptiualize highly efficient outsourcing
                        for cutting-edge web-readiness. Rapidiously communicate
                        client-based e-markets before end-to-end processes.
                        Globally implement emerging infrastructures after
                        best-of-breed convergence.
                      </p>
                      <h3 className="h4 mt-40">
                        Dalky is the only theme you will ever need
                      </h3>
                      <p>
                        Phosfluorescently incubate market-driven networks and
                        synergistic e-services. Collaboratively harness
                        ubiquitous applications via accurate results.
                        Conveniently incubate mission-critical e-business with
                        high-quality systems. Interactively provide access to
                        open-source e-business without compelling e-markets.
                        Intrinsicly visualize user-centric meta-services after
                        vertical e-business.
                      </p>
                      <div className="row mt-15">
                        <div className="col-md-6 mb-4">
                          <img
                            className="w-100 rounded-3"
                            src="assets/img/blog/blog_inner_1.jpg"
                            alt="Blog Image"
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <img
                            className="w-100 rounded-3"
                            src="assets/img/blog/blog_inner_2.jpg"
                            alt="Blog Image"
                          />
                        </div>
                      </div>
                      <p>
                        Globally cultivate ubiquitous growth strategies before
                        team building users. Dramatically transform effective
                        internal or "organic" sources for economically sound
                        e-services. Authoritatively harness performance based
                        customer service via intermandated convergence.
                        Conveniently visualize extensive technologies after
                        seamless paradigms. Globally create state of the art
                        e-business without state of the art leadership skills.
                      </p>
                      <p className="mb-n2">
                        Completely seize seamless e-tailers whereas
                        mission-critical ideas. Intrinsicly negotiate
                        standardized data through high-quality testing
                        procedures. Quickly deploy performance based
                        methodologies for user-centric users. Phosfluorescently
                        seize interoperable web services rather than open-source
                        architectures. Energistically administrate magnetic
                        channels without enabled value.
                      </p>
                    </div>
                    <div className="share-links clearfix">
                      <div className="row justify-content-between">
                        <div className="col-sm-auto">
                          <span className="share-links-title">Tags:</span>
                          <div className="tagcloud">
                            <Link to="/blog">Education</Link>
                            <Link to="/blog">Online</Link>
                          </div>
                        </div>
                        <div className="col-sm-auto text-xl-end">
                          <span className="share-links-title">Share:</span>
                          <ul className="social-links">
                            <li>
                              <Link to="https://facebook.com/" target="_blank">
                                <i className="fab fa-facebook-f" />
                              </Link>
                            </li>
                            <li>
                              <Link to="https://twitter.com/" target="_blank">
                                <i className="fab fa-twitter" />
                              </Link>
                            </li>
                            <li>
                              <Link to="https://linkedin.com/" target="_blank">
                                <i className="fab fa-linkedin-in" />
                              </Link>
                            </li>
                            <li>
                              <Link to="https://instagram.com/" target="_blank">
                                <i className="fab fa-instagram" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="th-comments-wrap">
                    <h2 className="blog-inner-title h3">
                      <i className="far fa-comments" /> Comments (03)
                    </h2>
                    <ul className="comment-list">
                      <li className="th-comment-item">
                        <div className="th-post-comment">
                          <div className="comment-avater">
                            <img
                              src="assets/img/blog/comment-author-1.jpg"
                              alt="Comment Author"
                            />
                          </div>
                          <div className="comment-content">
                            <span className="commented-on">
                              <i className="fal fa-calendar-alt" />
                              14 March, 2024
                            </span>
                            <h3 className="name">David Malan</h3>
                            <p className="text">
                              Collaboratively empower multifunctional e-commerce
                              for prospective applications. Seamlessly
                              productivate plug and play markets.
                            </p>
                            <div className="reply_and_edit">
                              <Link to="/blog-details" className="reply-btn">
                                <i className="fas fa-reply" />
                                Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                        <ul className="children">
                          <li className="th-comment-item">
                            <div className="th-post-comment">
                              <div className="comment-avater">
                                <img
                                  src="assets/img/blog/comment-author-2.jpg"
                                  alt="Comment Author"
                                />
                              </div>
                              <div className="comment-content">
                                <span className="commented-on">
                                  <i className="fal fa-calendar-alt" />
                                  15 March, 2024
                                </span>
                                <h3 className="name">Tara sing</h3>
                                <p className="text">
                                  Competently provide access to fully researched
                                  methods of empowerment without sticky models.
                                </p>
                                <div className="reply_and_edit">
                                  <Link
                                    to="/blog-details"
                                    className="reply-btn"
                                  >
                                    <i className="fas fa-reply" />
                                    Reply
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="th-comment-item">
                        <div className="th-post-comment">
                          <div className="comment-avater">
                            <img
                              src="assets/img/blog/comment-author-3.jpg"
                              alt="Comment Author"
                            />
                          </div>
                          <div className="comment-content">
                            <span className="commented-on">
                              <i className="fal fa-calendar-alt" />
                              16 March, 2024
                            </span>
                            <h3 className="name">Anadi Juila</h3>
                            <p className="text">
                              Collaboratively empower multifunctional e-commerce
                              for prospective applications. Seamlessly
                              productivate plug and play markets.
                            </p>
                            <div className="reply_and_edit">
                              <Link to="/blog-details" className="reply-btn">
                                <i className="fas fa-reply" />
                                Reply
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="th-comment-form">
                    <div className="form-title">
                      <h3 className="blog-inner-title mb-2">
                        <i className="fa-solid fa-reply" /> Leave a Comment
                      </h3>
                      <p className="form-text">
                        Your email address will not be published. Required
                        fields are marked *
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          placeholder="Your Name*"
                          className="form-control"
                        />
                        <i className="fal fa-user" />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          placeholder="Your Email*"
                          className="form-control"
                        />
                        <i className="fal fa-envelope" />
                      </div>
                      <div className="col-12 form-group">
                        <input
                          type="text"
                          placeholder="Website"
                          className="form-control"
                        />
                        <i className="fal fa-globe" />
                      </div>
                      <div className="col-12 form-group">
                        <textarea
                          placeholder="Write a Comment*"
                          className="form-control"
                          defaultValue={""}
                        />
                        <i className="fal fa-pencil" />
                      </div>
                      <div className="col-12 form-group mb-0">
                        <button className="th-btn">Post Comment</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-lg-5">
                  <div className="sidebar-area">
                    <div className="widget widget_search">
                      <form className="search-form">
                        <input type="text" placeholder="Enter Keyword" />
                        <button type="submit">
                          <i className="far fa-search" />
                        </button>
                      </form>
                    </div>
                    <div className="widget widget_categories">
                      <h3 className="widget_title">Categories</h3>
                      <ul>
                        <li>
                          <Link to="/blog">IT Solution</Link>
                        </li>
                        <li>
                          <Link to="/blog">SEO Marketing</Link>
                        </li>
                        <li>
                          <Link to="/blog">Web Development</Link>
                        </li>
                        <li>
                          <Link to="/blog">Cloud Solution</Link>
                        </li>
                        <li>
                          <Link to="/blog">Network Marketing</Link>
                        </li>
                        <li>
                          <Link to="/blog">UI/UX Design</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <h3 className="widget_title">Gallery Post</h3>
                      <div className="sidebar-gallery">
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_1.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_1.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_2.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_2.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_3.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_3.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_4.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_4.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_5.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_5.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                        <div className="gallery-thumb">
                          <img
                            src="assets/img/widget/gallery_1_6.jpg"
                            alt="Gallery Image"
                          />
                          <Link
                            to="assets/img/widget/gallery_1_6.jpg"
                            className="gallery-btn popup-image"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="widget">
                      <h3 className="widget_title">Recent Posts</h3>
                      <div className="recent-post-wrap">
                        <div className="recent-post">
                          <div className="media-img">
                            <Link to="/blog-details">
                              <img
                                src="assets/img/blog/recent-post-1-1.jpg"
                                alt="Blog Image"
                              />
                            </Link>
                          </div>
                          <div className="media-body">
                            <h4 className="post-title">
                              <Link
                                className="text-inherit"
                                to="/blog-details"
                              >
                                Unsatiable entreaties may collecting Power.
                              </Link>
                            </h4>
                            <div className="recent-post-meta">
                              <Link to="/blog">
                                <i className="fal fa-calendar-days" />
                                21 June, 2024
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="recent-post">
                          <div className="media-img">
                            <Link to="/blog-details">
                              <img
                                src="assets/img/blog/recent-post-1-2.jpg"
                                alt="Blog Image"
                              />
                            </Link>
                          </div>
                          <div className="media-body">
                            <h4 className="post-title">
                              <Link
                                className="text-inherit"
                                to="/blog-details"
                              >
                                Regional Manager limited time management.
                              </Link>
                            </h4>
                            <div className="recent-post-meta">
                              <Link to="/blog">
                                <i className="fal fa-calendar-days" />
                                22 June, 2024
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="recent-post">
                          <div className="media-img">
                            <Link to="/blog-details">
                              <img
                                src="assets/img/blog/recent-post-1-3.jpg"
                                alt="Blog Image"
                              />
                            </Link>
                          </div>
                          <div className="media-body">
                            <h4 className="post-title">
                              <Link
                                className="text-inherit"
                                to="/blog-details"
                              >
                                What’s the Holding Back It Solution Industry?
                              </Link>
                            </h4>
                            <div className="recent-post-meta">
                              <Link to="/blog">
                                <i className="fal fa-calendar-days" />
                                23 June, 2024
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="widget widget_tag_cloud">
                      <h3 className="widget_title">Popular Tags</h3>
                      <div className="tagcloud">
                        <Link to="/blog">Technology</Link>
                        <Link to="/blog">Advice</Link>
                        <Link to="/blog">Solution</Link>
                        <Link to="/blog">Consultion</Link>
                        <Link to="/blog">Business</Link>
                        <Link to="/blog">Services</Link>
                        <Link to="/blog">Start Up</Link>
                        <Link to="/blog">Agency</Link>
                        <Link to="/blog">Software</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
         <Footer1 />
          <div className="scroll-top">
            <svg
              className="progress-circle svg-content"
              width="100%"
              height="100%"
              viewBox="-1 -1 102 102"
            >
              <path
                d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                style={{
                  transition: "stroke-dashoffset 10ms linear 0s",
                  strokeDasharray: "307.919, 307.919",
                  strokeDashoffset: "307.919",
                }}
              />
            </svg>
          </div>
        
    </React.Fragment>
  );
};

export default BlogDetails;
