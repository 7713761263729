import React, { useState } from 'react'
import TabTitle from '../Utiles/FunctionTitle';
import { Link } from 'react-router-dom'

const Error = () => {
TabTitle('Webteck - Technology & IT Solutions HTML Template - 404 - Error Page')
  return (
<React.Fragment>
  <div className="cursor" />
  <div className="cursor2" />
  <div className="color-scheme-wrap active">
    <button className="switchIcon">
      <i className="fa-solid fa-palette" />
    </button>
    <h4 className="color-scheme-wrap-title">
      <i className="far fa-palette me-2" />
      Style Swicher
    </h4>
    <div className="color-switch-btns">
      <button data-color="#3E66F3">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#684DF4">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#008080">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#323F7C">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#FC3737">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#8a2be2">
        <i className="fa-solid fa-droplet" />
      </button>
    </div>
    <Link
      to="https://themeforest.net/user/themeholy"
      className="th-btn text-center w-100"
    >
      <i className="fa fa-shopping-cart me-2" /> Purchase
    </Link>
  </div>
  
  <div className="sidemenu-wrapper">
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls">
        <i className="far fa-times" />
      </button>
      <div className="widget woocommerce widget_shopping_cart">
        <h3 className="widget_title">Shopping cart</h3>
        <div className="widget_shopping_cart_content">
          <ul className="woocommerce-mini-cart cart_list product_list_widget">
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_1.jpg"
                  alt="Cart Image"
                />
                Gaming Computer
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  940.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_2.jpg"
                  alt="Cart Image"
                />
                Smartphone Vivo V9
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  899.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_3.jpg"
                  alt="Cart Image"
                />
                SanDisk Flash Drive
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  756.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_4.jpg"
                  alt="Cart Image"
                />
                Smart Power Bank
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  723.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_5.jpg"
                  alt="Cart Image"
                />
                Apple Smartwatch
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  1080.00
                </span>
              </span>
            </li>
          </ul>
          <p className="woocommerce-mini-cart__total total">
            <strong>Subtotal:</strong>
            <span className="woocommerce-Price-amount amount">
              <span className="woocommerce-Price-currencySymbol">$</span>4398.00
            </span>
          </p>
          <p className="woocommerce-mini-cart__buttons buttons btn-wrap">
            <Link to="../cart" className="th-btn wc-forward">
              View cart
            </Link>
            <Link to="../checkout" className="th-btn checkout wc-forward">
              Checkout
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="popup-search-box d-none d-lg-block">
    <button className="searchClose">
      <i className="fal fa-times" />
    </button>
    <form action="#">
      <input type="text" placeholder="What are you looking for?" />
      <button type="submit">
        <i className="fal fa-search" />
      </button>
    </form>
  </div>
  <div className="th-menu-wrapper">
    <div className="th-menu-area text-center">
      <button className="th-menu-toggle">
        <i className="fal fa-times" />
      </button>
      <div className="mobile-logo">
        <Link className="icon-masking" to="index">
          <span data-mask-src="assets/img/logo.svg" className="mask-icon" />
          <img src="assets/img/logo.svg" alt="Webteck" />
        </Link>
      </div>
      <div className="th-mobile-menu">
        <ul>
          <li className="menu-item-has-children mega-menu-wrap">
            <Link to="#">Home</Link>
            <ul className="mega-menu">
              <li>
                <Link to="#">Multipage</Link>
                <ul>
                  <li>
                    <Link to="/">Digital Agency</Link>
                  </li>
                  <li>
                    <Link to="/home-web-development">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/home-software-company">Software Company</Link>
                  </li>
                  <li>
                    <Link to="/home-software-company-2">
                      Software Company Style 2
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-startup-company">Startup Company</Link>
                  </li>
                  <li>
                    <Link to="/home-it-solution">IT Solution</Link>
                  </li>
                  <li>
                    <Link to="../home-web-agency">Web Agency</Link>
                  </li>
                  <li>
                    <Link to="../home-startup">Home Startup</Link>
                  </li>
                  <li>
                    <Link to="../home-game-solution">Game Solution</Link>
                  </li>
                  <li>
                    <Link to="../home-sass-marketing">Sass Marketing</Link>
                  </li>
                  <li>
                    <Link to="../home-sass-landing">Sass Landing</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Multipage</Link>
                <ul>
                  <li>
                    <Link to="../home-sass-landing-2">Sass Landing Style 2</Link>
                  </li>
                  <li>
                    <Link to="/home-app-landing">App Landing</Link>
                  </li>
                  <li>
                    <Link to="../home-ai-technology">AI Technology</Link>
                  </li>
                  <li>
                    <Link to="../home-cyber-security">Cyber Security</Link>
                  </li>
                  <li>
                    <Link to="../home-cyber-security-2">
                      Cyber Security Style 2
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-it-company">IT Company</Link>
                  </li>
                  <li>
                    <Link to="../home-digital-marking">Digital Marking</Link>
                  </li>
                  <li>
                    <Link to="../home-it-agency">IT Agency</Link>
                  </li>
                  <li>
                    <Link to="../home-crm">Home CRM</Link>
                  </li>
                  <li>
                    <Link to="../home-it-consulting">IT Consulting</Link>
                  </li>
                  <li>
                    <Link to="../home-help-desk">Home Help Desk</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Onepage</Link>
                <ul>
                  <li>
                    <Link to="../home-digital-agency-op">
                      Digital Agency Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-web-development-op">
                      Web Development Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-software-company-op">
                      Software Company Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-software-company-2-op">
                      Software Company Style 2 Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-startup-company-op">
                      Startup Company Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-it-solution-op">IT Solution Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-web-agency-op">Web Agency Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-startup-op">Home Startup Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-game-solution-op">
                      Game Solution Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-sass-marketing-op">
                      Sass Marketing Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-sass-landing-op">Sass Landing Onepage</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Onepage</Link>
                <ul>
                  <li>
                    <Link to="../home-sass-landing-2-op">
                      Sass Landing Style 2 Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-app-landing-op">App Landing Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-ai-technology-op">
                      AI Technology Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-cyber-security-op">
                      Cyber Security Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-cyber-security-2-op">
                      Cyber Security Style 2 Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-it-company-op">IT Company Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-digital-marking-op">
                      Digital Marking Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-it-agency-op">IT Agency Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-crm-op">Home CRM Onepage</Link>
                  </li>
                  <li>
                    <Link to="../home-it-consulting-op">
                      IT Consulting Onepage
                    </Link>
                  </li>
                  <li>
                    <Link to="../home-help-desk-op">Home Help Desk</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Services</Link>
            <ul className="sub-menu">
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/service-details">Services Details</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Pages</Link>
            <ul className="sub-menu">
              <li className="menu-item-has-children">
                <Link to="#">Shop</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="../shop">Shop</Link>
                  </li>
                  <li>
                    <Link to="../shop-details">Shop Details</Link>
                  </li>
                  <li>
                    <Link to="../cart">Cart Page</Link>
                  </li>
                  <li>
                    <Link to="../checkout">Checkout</Link>
                  </li>
                  <li>
                    <Link to="../wishlist">Wishlist</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="../team">Team</Link>
              </li>
              <li>
                <Link to="/team-details">Team Details</Link>
              </li>
              <li>
                <Link to="/project">Project</Link>
              </li>
              <li>
                <Link to="/project-details">Project Details</Link>
              </li>
              <li>
                <Link to="../gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="../faq">Faq Page</Link>
              </li>
              <li>
                <Link to="../error">Error Page</Link>
              </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Blog</Link>
            <ul className="sub-menu">
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/blog-details">Blog Details</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <header className="th-header header-layout1">
    <div className="header-top">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
          <div className="col-auto d-none d-lg-block">
            <div className="header-links">
              <ul>
                <li>
                  <i className="fas fa-map-location" />
                  54 NJ-12, Flemington, United States
                </li>
                <li>
                  <i className="fas fa-phone" />
                  <Link to="tel:+1539873657">+153-987-3657</Link>
                </li>
                <li>
                  <i className="fas fa-envelope" />
                  <Link to="mailto:info@webteck.com">info@webteck.com</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto">
            <div className="header-social">
              <span className="social-title">Follow Us On : </span>
              <Link to="https://www.facebook.com/">
                <i className="fab fa-facebook-f" />
              </Link>
              <Link to="https://www.twitter.com/">
                <i className="fab fa-twitter" />
              </Link>
              <Link to="https://www.linkedin.com/">
                <i className="fab fa-linkedin-in" />
              </Link>
              <Link to="https://www.instagram.com/">
                <i className="fab fa-instagram" />
              </Link>
              <Link to="https://www.youtube.com/">
                <i className="fab fa-youtube" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sticky-wrapper">
      <div className="menu-area">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="header-logo">
                <Link className="icon-masking" to="index">
                  <span
                    data-mask-src="assets/img/logo.svg"
                    className="mask-icon"
                  />
                  <img src="assets/img/logo.svg" alt="Webteck" />
                </Link>
              </div>
            </div>
            <div className="col-auto">
              <nav className="main-menu d-none d-lg-inline-block">
                <ul>
                  <li className="menu-item-has-children mega-menu-wrap">
                    <Link to="#">Home</Link>
                    <ul className="mega-menu">
                      <li>
                        <Link to="#">Multipage</Link>
                        <ul>
                          <li>
                            <Link to="/">Digital Agency</Link>
                          </li>
                          <li>
                            <Link to="/home-web-development">
                              Web Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-software-company">
                              Software Company
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-software-company-2">
                              Software Company Style 2
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-startup-company">
                              Startup Company
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-it-solution">IT Solution</Link>
                          </li>
                          <li>
                            <Link to="../home-web-agency">Web Agency</Link>
                          </li>
                          <li>
                            <Link to="../home-startup">Home Startup</Link>
                          </li>
                          <li>
                            <Link to="../home-game-solution">Game Solution</Link>
                          </li>
                          <li>
                            <Link to="../home-sass-marketing">
                              Sass Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-sass-landing">Sass Landing</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">Multipage</Link>
                        <ul>
                          <li>
                            <Link to="../home-sass-landing-2">
                              Sass Landing Style 2
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-app-landing">App Landing</Link>
                          </li>
                          <li>
                            <Link to="../home-ai-technology">AI Technology</Link>
                          </li>
                          <li>
                            <Link to="../home-cyber-security">
                              Cyber Security
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-cyber-security-2">
                              Cyber Security Style 2
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-it-company">IT Company</Link>
                          </li>
                          <li>
                            <Link to="../home-digital-marking">
                              Digital Marking
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-it-agency">IT Agency</Link>
                          </li>
                          <li>
                            <Link to="../home-crm">Home CRM</Link>
                          </li>
                          <li>
                            <Link to="../home-it-consulting">IT Consulting</Link>
                          </li>
                          <li>
                            <Link to="../home-help-desk">Home Help Desk</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">Onepage</Link>
                        <ul>
                          <li>
                            <Link to="../home-digital-agency-op">
                              Digital Agency Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-web-development-op">
                              Web Development Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-software-company-op">
                              Software Company Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-software-company-2-op">
                              Software Company Style 2 Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-startup-company-op">
                              Startup Company Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-it-solution-op">
                              IT Solution Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-web-agency-op">
                              Web Agency Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-startup-op">
                              Home Startup Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-game-solution-op">
                              Game Solution Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-sass-marketing-op">
                              Sass Marketing Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-sass-landing-op">
                              Sass Landing Onepage
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">Onepage</Link>
                        <ul>
                          <li>
                            <Link to="../home-sass-landing-2-op">
                              Sass Landing Style 2 Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-app-landing-op">
                              App Landing Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-ai-technology-op">
                              AI Technology Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-cyber-security-op">
                              Cyber Security Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-cyber-security-2-op">
                              Cyber Security Style 2 Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-it-company-op">
                              IT Company Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-digital-marking-op">
                              Digital Marking Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-it-agency-op">
                              IT Agency Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-crm-op">Home CRM Onepage</Link>
                          </li>
                          <li>
                            <Link to="../home-it-consulting-op">
                              IT Consulting Onepage
                            </Link>
                          </li>
                          <li>
                            <Link to="../home-help-desk-op">Home Help Desk</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="#">Services</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/service">Services</Link>
                      </li>
                      <li>
                        <Link to="/service-details">Services Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="#">Pages</Link>
                    <ul className="sub-menu">
                      <li className="menu-item-has-children">
                        <Link to="#">Shop</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="../shop">Shop</Link>
                          </li>
                          <li>
                            <Link to="../shop-details">Shop Details</Link>
                          </li>
                          <li>
                            <Link to="../cart">Cart Page</Link>
                          </li>
                          <li>
                            <Link to="../checkout">Checkout</Link>
                          </li>
                          <li>
                            <Link to="../wishlist">Wishlist</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="../team">Team</Link>
                      </li>
                      <li>
                        <Link to="/team-details">Team Details</Link>
                      </li>
                      <li>
                        <Link to="/project">Project</Link>
                      </li>
                      <li>
                        <Link to="/project-details">Project Details</Link>
                      </li>
                      <li>
                        <Link to="../gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="../faq">Faq Page</Link>
                      </li>
                      <li>
                        <Link to="../error">Error Page</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="#">Blog</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/blog-details">Blog Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
              <div className="header-button">
                <button
                  type="button"
                  className="icon-btn sideMenuToggler d-inline-block d-lg-none"
                >
                  <i className="fal fa-shopping-cart" />
                  <span className="badge">5</span>
                </button>
                <button
                  type="button"
                  className="th-menu-toggle d-inline-block d-lg-none"
                >
                  <i className="far fa-bars" />
                </button>
              </div>
            </div>
            <div className="col-auto d-none d-lg-block">
              <div className="header-button">
                <button type="button" className="icon-btn searchBoxToggler">
                  <i className="fal fa-search" />
                </button>
                <button type="button" className="icon-btn sideMenuToggler">
                  <i className="fal fa-shopping-cart" />
                  <span className="badge">5</span>
                </button>
                <Link to="/contact" className="th-btn style3 shadow-none">
                  Make Appointment
                  <i className="fas fa-arrow-right ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-bg" />
      </div>
    </div>
  </header>
  <div
    className="breadcumb-wrapper"
    data-bg-src="assets/img/bg/breadcumb-bg.jpg"
  >
    <div className="container">
      <div className="breadcumb-content">
        <h1 className="breadcumb-title">404 - Error Page</h1>
        <ul className="breadcumb-menu">
          <li>
            <Link to="index">Home</Link>
          </li>
          <li>404 - Error Page</li>
        </ul>
      </div>
    </div>
  </div>
  <section className="space">
    <div className="container">
      <div className="error-img">
        <img src="assets/img/theme-img/error.svg" alt="404 image" />
      </div>
      <div className="error-content">
        <h2 className="error-title">
          <span className="text-theme">OooPs!</span> Page Not Found
        </h2>
        <p className="error-text">
          Oops! The page you are looking for does not exist. It might have been
          moved or deleted.
        </p>
        <Link to="index" className="th-btn">
          <i className="fal fa-home me-2" />
          Back To Home
        </Link>
      </div>
    </div>
  </section>
  <footer className="footer-wrapper footer-layout1">
    <div className="footer-top">
      <div className="logo-bg" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-3">
            <div className="footer-logo">
              <Link className="icon-masking" to="index">
                <span
                  data-mask-src="assets/img/logo-white.svg"
                  className="mask-icon"
                />
                <img src="assets/img/logo-white.svg" alt="Webteck" />
              </Link>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="footer-contact-wrap">
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-phone" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Quick Call Us:</span>
                  <Link to="tel:+19088000393" className="footer-contact_link">
                    +190-8800-0393
                  </Link>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-envelope" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Mail Us On:</span>
                  <Link
                    to="mailto:info@webteck.com"
                    className="footer-contact_link"
                  >
                    info@webteck.com
                  </Link>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Visit Location:</span>
                  <Link
                    to="https://www.google.com/maps"
                    className="footer-contact_link"
                  >
                    54 Flemington, USA
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xxl-3 col-xl-4">
            <div className="widget footer-widget">
              <h3 className="widget_title">About Company</h3>
              <div className="th-widget-about">
                <p className="about-text">
                  Professionally redefine transparent ROI through low-risk
                  high-yield imperatives. Progressively create empowered. cost
                  effective users via team driven.
                </p>
                <div className="th-social">
                  <Link to="https://www.facebook.com/">
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link to="https://www.twitter.com/">
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="https://www.linkedin.com/">
                    <i className="fab fa-linkedin-in" />
                  </Link>
                  <Link to="https://www.whatsapp.com/">
                    <i className="fab fa-whatsapp" />
                  </Link>
                  <Link to="https://www.youtube.com/">
                    <i className="fab fa-youtube" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">Quick Links</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="../team">Meet Our Team</Link>
                  </li>
                  <li>
                    <Link to="/project">Our Projects</Link>
                  </li>
                  <li>
                    <Link to="../faq">Help &amp; FAQs</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">IT SERVICES</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/service-details">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Business Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Product Management</Link>
                  </li>
                  <li>
                    <Link to="/service-details">UI/UX Design</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Cloud services</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget footer-widget">
              <h3 className="widget_title">Recent Posts</h3>
              <div className="recent-post-wrap">
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-1.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Unsatiable entreaties may collecting Power.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        21 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-2.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Regional Manager limited time management.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        22 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap bg-title">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <p className="copyright-text">
              Copyright <i className="fal fa-copyright" /> 2024
              <Link to="https://themeforest.net/user/themeholy">Themeholy</Link>.
              All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-end d-none d-lg-block">
            <div className="footer-links">
              <ul>
                <li>
                  <Link to="/about">Terms &amp; Condition</Link>
                </li>
                <li>
                  <Link to="/about">Careers</Link>
                </li>
                <li>
                  <Link to="/about">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape-left">
      <img src="assets/img/shape/footer_shape_2.svg" alt="shape" />
    </div>
    <div className="shape-right">
      <div className="particle-1" id="particle-5" />
    </div>
  </footer>
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  
</React.Fragment>
  )
}

export default Error