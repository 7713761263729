import React, { useState } from 'react'
import TabTitle from '../Utiles/FunctionTitle';
import { Link } from 'react-router-dom'
import Footer1 from '../Common/Footer1';

const Service = () => {
TabTitle('Webteck - Technology & IT Solutions HTML Template - Services')
  return (
<React.Fragment>
  <div
    className="breadcumb-wrapper"
    data-bg-src="assets/img/bg/breadcumb-bg.jpg"
  >
    <div className="container">
      <div className="breadcumb-content">
        <h1 className="breadcumb-title">Services</h1>
        <ul className="breadcumb-menu">
          <li>
            <Link to="index">Home</Link>
          </li>
          <li>Services</li>
        </ul>
      </div>
    </div>
  </div>
  <section className="space" id="service-sec">
    <div className="container">
      <div className="row gy-4">
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">01</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Web Development</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">02</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_2.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">UI/UX Design</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">03</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Digital Marketing</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">04</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_4.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Business Analysis</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">05</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_5.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Software Services</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">06</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_6.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Machine Learning</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">07</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_7.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">IT Management</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">08</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_8.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Cloud Computing</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4">
          <div className="service-card">
            <div className="service-card_number">09</div>
            <div className="shape-icon">
              <img src="assets/img/icon/service_card_9.svg" alt="Icon" />
              <span className="dots" />
            </div>
            <h3 className="box-title">
              <Link to="/service-details">Backup &amp; Recovery</Link>
            </h3>
            <p className="service-card_text">
              Intrinsicly redefine competitive e-business before adaptive
              potentialiti. Professionally build progressive users with.
            </p>
            <Link to="/service-details" className="th-btn">
              Read More
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
            <div className="bg-shape">
              <img src="assets/img/bg/service_card_bg.png" alt="bg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="position-relative space">
    <div className="th-bg-img" data-bg-src="assets/img/bg/cta_bg_2.jpg">
      <img src="assets/img/bg/bg_overlay_1.png" alt="overlay" />
    </div>
    <div className="container z-index-common">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-7 col-md-9 text-center">
          <div className="title-area mb-35">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              CONTACT US
            </span>
            <h2 className="sec-title text-white">
              Need Any Kind Of IT Solution For
              <span className="text-theme fw-normal">Your Business?</span>
            </h2>
          </div>
          <Link to="/contact" className="th-btn style3">
            Get In Touch
          </Link>
        </div>
      </div>
    </div>
  </section>
  <section
    className="bg-smoke"
    id="process-sec"
    data-bg-src="assets/img/bg/process_bg_1.png"
  >
    <div className="container space">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          WORK PROCESS
        </span>
        <h2 className="sec-title">
          How to work <span className="text-theme">it!</span>
        </h2>
      </div>
      <div className="process-card-area">
        <div className="process-line">
          <img src="assets/img/bg/process_line.svg" alt="line" />
        </div>
        <div className="row gy-40">
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">01</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_1.svg" alt="icon" />
              </div>
              <h2 className="box-title">Select a project</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">02</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_2.svg" alt="icon" />
              </div>
              <h2 className="box-title">Project analysis</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">03</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_3.svg" alt="icon" />
              </div>
              <h2 className="box-title">Plan Execute</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">04</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_4.svg" alt="icon" />
              </div>
              <h2 className="box-title">Deliver result</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="bg-auto space" data-bg-src="assets/img/bg/testi_bg_2.png">
    <div className="container">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          CUSTOMER FEEDBACK
        </span>
        <h2 className="sec-title">
          What Happy Clients Says
          <br />
          <span className="text-theme fw-normal">About Us?</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="testiSlider2"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"1"},"1200":{"slidesPerView":"2"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-box_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-box_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-box_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-box_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-box_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-box_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#testiSlider2"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>
        <button
          data-slider-next="#testiSlider2"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
    <div
      className="shape-mockup moving d-none d-xl-block"
      data-bottom="0%"
      data-left="10%"
    >
      <img src="assets/img/shape/line_1.png" alt="shape" />
    </div>
    <div
      className="shape-mockup jump d-none d-xl-block"
      data-top="20%"
      data-right="2%"
    >
      <img src="assets/img/shape/line_2.png" alt="shape" />
    </div>
  </section>
  <Footer1 />
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  
</React.Fragment>
  )
}

export default Service