import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Footer1 from '../Common/Footer1'

const DigitalAgency = () => {
  return (
<React.Fragment>
  <div className="th-hero-wrapper hero-4" id="hero">
    <div className="body-particle" id="body-particle" />
    <div className="hero-img tilt-active">
      <img src="assets/img/hero/hero_img_4_1.png" alt="Hero Image" />
    </div>
    <div className="container">
      <div className="hero-style4">
        <div className="ripple-shape">
          <span className="ripple-1" />
          <span className="ripple-2" />
          <span className="ripple-3" />
          <span className="ripple-4" />
          <span className="ripple-5" />
          <span className="ripple-6" />
        </div>
        <span className="hero-subtitle">Best Marketing Service</span>
        <h1 className="hero-title">Make The Easiest</h1>
        <h1 className="hero-title">Solution For You</h1>
        <p className="hero-text">
        At HRHK Technology, we offer expert IT services to manage and maintain your technology infrastructure, ensuring secure, efficient, and reliable operations. Our solutions are tailored to align with your business goals and enhance productivity.
        </p>
        <div className="btn-group">
          <Link to="/about" className="th-btn">
            ABOUT US
            <i className="fa-regular fa-arrow-right ms-2" />
          </Link>
        </div>
      </div>
    </div>
    <div className="triangle-1" />
    <div className="triangle-2" />
    <div className="hero-shape2">
      <img src="assets/img/hero/hero_shape_2_2.png" alt="shape" />
    </div>
    <div className="hero-shape3">
      <img src="assets/img/hero/hero_shape_2_3.png" alt="shape" />
    </div>
  </div>
  <div className="overflow-hidden space" id="about-sec">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-6 mb-30 mb-xl-0">
          <div className="img-box4 tilt-active">
            <div className="img-shape icon-masking">
              <span
                className="mask-icon"
                data-mask-src="assets/img/normal/about_3_1-shape.png"
              />{" "}
              <img src="assets/img/normal/about_3_1-shape.png" alt="img" />
            </div>
            <img src="assets/img/normal/about_3_1.png" alt="About" />{" "}
            <Link
              to="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
              className="play-btn popup-video"
            >
              <i className="fas fa-play" />
            </Link>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="title-area mb-35 text-center text-xl-start">
            <div className="shadow-title">ABOUT US</div>
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />{" "}
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              About HRHK IT SOLUTIONS
            </span>
            <h2 className="sec-title">
              We Are Increasing Business Success With{" "}
              <span className="text-theme">Technology</span>
            </h2>
          </div>
          <p className="mt-n2 mb-30 text-center text-xl-start">
            Synergistically incentivize effective imperatives through fully
            researched intellectual capital. Appropriately fashion client-based.
          </p>
          <div className="achivement-tab filter-menu-active indicator-active">
            <button data-filter=".cat1" className="active" type="button">
              Award Winning
            </button>{" "}
            <button data-filter=".cat2" type="button">
              Technology Index
            </button>
          </div>
          <div className="achivement-box-area filter-active-cat1">
            <div className="filter-item w-100 cat1">
              <div className="achivement-box">
                <div className="achivement-box_img">
                  <img src="assets/img/normal/about_3_1_1.jpg" alt="About" />
                </div>
                <div className="media-body">
                  <h3 className="box-title">An Award-Winning Company.</h3>
                  <p className="achivement-box_text">
                    Monotonically matrix extensible applications and go forward
                    communities. Synergistically extend client-based
                    manufactured.
                  </p>
                  <Link to="/about" className="th-btn">
                    About More
                  </Link>
                </div>
              </div>
            </div>
            <div className="filter-item w-100 cat2">
              <div className="achivement-box">
                <div className="achivement-box_img">
                  <img src="assets/img/normal/about_3_1_2.jpg" alt="About" />
                </div>
                <div className="media-body">
                  <h3 className="box-title">Having 25+ Years Of Experience.</h3>
                  <p className="achivement-box_text">
                    Monotonically matrix extensible applications and go forward
                    communities. Synergistically extend client-based
                    manufactured.
                  </p>
                  <Link to="/about" className="th-btn">
                    About More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section
    className="bg-top-center z-index-common space-top"
    id="service-sec"
    data-bg-src="assets/img/bg/service_bg_2.jpg"
  >
    <div className="container">
      <div className="row justify-content-lg-between justify-content-center align-items-center">
        <div className="col-lg-6 col-sm-9 pe-xl-5">
          <div className="title-area text-center text-lg-start">
            <div className="shadow-title color2">SERVICES</div>
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />{" "}
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              WHAT WE DO
            </span>
            <h2 className="sec-title text-white">
              We Provide Exclusive Service For{" "}
              <span className="text-theme">Your Business</span>
            </h2>
          </div>
        </div>
        <div className="col-auto">
          <div className="sec-btn">
            <Link to="/service" className="th-btn style3">
              VIEW ALL SERVICES
              <i className="fa-regular fa-arrow-right ms-2" />
            </Link>
          </div>
        </div>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="serviceSlider1"
          data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="service-grid">
                <div className="service-grid_icon">
                  <img src="assets/img/icon/service_card_1.svg" alt="Icon" />
                </div>
                <div className="service-grid_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Web Development</Link>
                  </h3>
                  <p className="service-grid_text">
                    Continually engage customized infomediarie and quality
                    growth strategies. Appropriately.
                  </p>
                  <Link to="/service-details" className="th-btn">
                    Read More
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                  <div className="bg-shape">
                    <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-grid">
                <div className="service-grid_icon">
                  <img src="assets/img/icon/service_card_2.svg" alt="Icon" />
                </div>
                <div className="service-grid_content">
                  <h3 className="box-title">
                    <Link to="/service-details">UI/UX Design</Link>
                  </h3>
                  <p className="service-grid_text">
                    Continually engage customized infomediarie and quality
                    growth strategies. Appropriately.
                  </p>
                  <Link to="/service-details" className="th-btn">
                    Read More
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                  <div className="bg-shape">
                    <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-grid">
                <div className="service-grid_icon">
                  <img src="assets/img/icon/service_card_3.svg" alt="Icon" />
                </div>
                <div className="service-grid_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Digital Marketing</Link>
                  </h3>
                  <p className="service-grid_text">
                    Continually engage customized infomediarie and quality
                    growth strategies. Appropriately.
                  </p>
                  <Link to="/service-details" className="th-btn">
                    Read More
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                  <div className="bg-shape">
                    <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-grid">
                <div className="service-grid_icon">
                  <img src="assets/img/icon/service_card_4.svg" alt="Icon" />
                </div>
                <div className="service-grid_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Business Analysis</Link>
                  </h3>
                  <p className="service-grid_text">
                    Continually engage customized infomediarie and quality
                    growth strategies. Appropriately.
                  </p>
                  <Link to="/service-details" className="th-btn">
                    Read More
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                  <div className="bg-shape">
                    <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-grid">
                <div className="service-grid_icon">
                  <img src="assets/img/icon/service_card_5.svg" alt="Icon" />
                </div>
                <div className="service-grid_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Software Services</Link>
                  </h3>
                  <p className="service-grid_text">
                    Continually engage customized infomediarie and quality
                    growth strategies. Appropriately.
                  </p>
                  <Link to="/service-details" className="th-btn">
                    Read More
                    <i className="fas fa-arrow-right ms-2" />
                  </Link>
                  <div className="bg-shape">
                    <img src="assets/img/bg/service_grid_bg.png" alt="bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="why-sec-v2" data-bg-src="assets/img/bg/why_bg_2.jpg">
    <div className="container space">
      <div className="row align-items-center flex-row-reverse">
        <div className="col-xl-6 mb-30 mb-xl-0">
          <div className="img-box5">
            <img
              className="tilt-active"
              src="assets/img/normal/why_2_1.png"
              alt="Why"
            />
            <div className="year-counter">
              <h3 className="year-counter_number">
                <span className="counter-number">10</span>k+
              </h3>
              <p className="year-counter_text">Clients Active</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="title-area mb-35">
            <div className="shadow-title color3">Choose US</div>
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />{" "}
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              WHY CHOOSE US
            </span>
            <h2 className="sec-title">
              More Than 24+ Years Experience We Provide{" "}
              <span className="text-theme">IT Services</span>
            </h2>
          </div>
          <p className="mt-n2 mb-30">
            Collaboratively envisioneer user friendly supply chains and cross
            unit imperative. Authoritativel fabricate competitive resource and
            holistic. Holisticly restore real time resources whereas
            standardized networks.
          </p>
          <div className="feature-circle-wrap">
            <div className="feature-circle">
              <div className="progressbar" data-path-color="#684DF4">
                <div className="circle" data-percent={90}>
                  <div className="circle-num" />
                </div>
              </div>
              <div className="media-body">
                <h3 className="feature-circle_title">Business Grow</h3>
                <p className="feature-circle_text">
                  Efficiently transition top-line ideas before market.
                </p>
              </div>
            </div>
            <div className="feature-circle">
              <div className="progressbar" data-path-color="#684DF4">
                <div className="circle" data-percent={95}>
                  <div className="circle-num" />
                </div>
              </div>
              <div className="media-body">
                <h3 className="feature-circle_title">Quality Products</h3>
                <p className="feature-circle_text">
                  Efficiently transition top-line ideas before market.
                </p>
              </div>
            </div>
          </div>
          <Link to="/about" className="th-btn">
            LEARN MORE
            <i className="fa-regular fa-arrow-right ms-2" />
          </Link>
        </div>
      </div>
    </div>
  </div>
  <section className="space" id="project-sec">
    <div className="container">
      <div className="title-area text-center">
        <div className="shadow-title">PROJECTS</div>
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />{" "}
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          LATEST PROJECTS
        </span>
        <h2 className="sec-title">
          Our Recent Latest <span className="text-theme">Projects</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="projectSlider2"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_1.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_1.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">IT Consultency</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_2.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_2.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">Web Development</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_3.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_3.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">Website Design</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_4.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_4.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">SEO Optimization</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_1.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_1.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">IT Consultency</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_2.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_2.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">Web Development</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_3.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_3.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">Website Design</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="project-grid">
                <div className="project-grid_img">
                  <img
                    src="assets/img/project/project_2_4.jpg"
                    alt="project image"
                  />{" "}
                  <Link
                    to="assets/img/project/project_2_4.jpg"
                    className="play-btn style3 popup-image"
                  >
                    <i className="far fa-plus" />
                  </Link>
                </div>
                <div className="project-grid_content">
                  <h3 className="box-title">
                    <Link to="/project-details">SEO Optimization</Link>
                  </h3>
                  <p className="project-grid_text">
                    Distinctively enable premium expertise for multifunctional
                    action items. Energistically benchmark worldwide
                    communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#projectSlider2"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>{" "}
        <button
          data-slider-next="#projectSlider2"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
  </section>
  <div
    className="bg-theme space-extra"
    data-bg-src="assets/img/bg/counter_bg_1.png"
  >
    <div className="container py-2">
      <div className="row gy-40 justify-content-between">
        <div className="col-6 col-lg-auto">
          <div className="counter-card">
            <div className="counter-card_icon">
              <img src="assets/img/icon/counter_1_1.svg" alt="Icon" />
            </div>
            <div className="media-body">
              <h2 className="counter-card_number">
                <span className="counter-number">986</span>+
              </h2>
              <p className="counter-card_text">Finished Project</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-auto">
          <div className="counter-card">
            <div className="counter-card_icon">
              <img src="assets/img/icon/counter_1_2.svg" alt="Icon" />
            </div>
            <div className="media-body">
              <h2 className="counter-card_number">
                <span className="counter-number">896</span>+
              </h2>
              <p className="counter-card_text">Happy Clients</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-auto">
          <div className="counter-card">
            <div className="counter-card_icon">
              <img src="assets/img/icon/counter_1_3.svg" alt="Icon" />
            </div>
            <div className="media-body">
              <h2 className="counter-card_number">
                <span className="counter-number">396</span>+
              </h2>
              <p className="counter-card_text">Skilled Experts</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-auto">
          <div className="counter-card">
            <div className="counter-card_icon">
              <img src="assets/img/icon/counter_1_4.svg" alt="Icon" />
            </div>
            <div className="media-body">
              <h2 className="counter-card_number">
                <span className="counter-number">496</span>+
              </h2>
              <p className="counter-card_text">Honorable Awards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section
    className="bg-smoke"
    id="process-sec"
    data-bg-src="assets/img/bg/process_bg_1.png"
  >
    <div className="container space">
      <div className="title-area text-center">
        <div className="shadow-title">PROCESS</div>
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />{" "}
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          WORK PROCESS
        </span>
        <h2 className="sec-title">
          How to work <span className="text-theme">it!</span>
        </h2>
      </div>
      <div className="process-card-area">
        <div className="process-line">
          <img src="assets/img/bg/process_line.svg" alt="line" />
        </div>
        <div className="row gy-40">
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">01</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_1.svg" alt="icon" />
              </div>
              <h2 className="box-title">Select a project</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">02</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_2.svg" alt="icon" />
              </div>
              <h2 className="box-title">Project analysis</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">03</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_3.svg" alt="icon" />
              </div>
              <h2 className="box-title">Plan Execute</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3 process-card-wrap">
            <div className="process-card">
              <div className="process-card_number">04</div>
              <div className="process-card_icon">
                <img src="assets/img/icon/process_card_4.svg" alt="icon" />
              </div>
              <h2 className="box-title">Deliver result</h2>
              <p className="process-card_text">
                Continua scale empowered metrics with cost effective innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="" id="team-sec">
    <div
      className="brand-sec1"
      data-pos-for="#process-sec"
      data-sec-pos="top-half"
    >
      <div className="container py-5">
        <div className="slider-area text-center">
          <div
            className="swiper th-slider"
            id="brandSlider1"
            data-slider-options='{"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"},"1400":{"slidesPerView":"5"}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_1.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_2.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_3.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_4.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_5.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_6.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_1.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_2.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_3.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_4.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_5.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="brand-box py-20">
                  <img src="assets/img/brand/brand_2_6.png" alt="Brand Logo" />
                </div>
              </div>
            </div>
          </div>
          <button
            data-slider-prev="#brandSlider1"
            className="slider-arrow style3 slider-prev"
          >
            <i className="far fa-arrow-left" />
          </button>{" "}
          <button
            data-slider-next="#brandSlider1"
            className="slider-arrow style3 slider-next"
          >
            <i className="far fa-arrow-right" />
          </button>
        </div>
      </div>
    </div>
    <div className="container space">
      <div className="title-area text-center">
        <div className="shadow-title">Team</div>
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />{" "}
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          GREAT TEAM
        </span>
        <h2 className="sec-title">
          See Our Skilled Expert <span className="text-theme">Team</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="teamSlider1"
          data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_1.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Rayan Athels</Link>
                </h3>
                <span className="team-desig">Founder &amp; CEO</span>
                <div className="box-particle" id="team-p1" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_2.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Alex Furnandes</Link>
                </h3>
                <span className="team-desig">Project Manager</span>
                <div className="box-particle" id="team-p2" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_3.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Mary Crispy</Link>
                </h3>
                <span className="team-desig">Cheif Expert</span>
                <div className="box-particle" id="team-p3" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_4.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Henry Joshep</Link>
                </h3>
                <span className="team-desig">Product Manager</span>
                <div className="box-particle" id="team-p4" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_1.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Rayan Athels</Link>
                </h3>
                <span className="team-desig">Founder &amp; CEO</span>
                <div className="box-particle" id="team-p1" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_2.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Alex Furnandes</Link>
                </h3>
                <span className="team-desig">Project Manager</span>
                <div className="box-particle" id="team-p2" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_3.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Mary Crispy</Link>
                </h3>
                <span className="team-desig">Cheif Expert</span>
                <div className="box-particle" id="team-p3" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="th-team team-grid">
                <div className="team-img">
                  <img src="assets/img/team/team_3_4.jpg" alt="Team" />
                </div>
                <div className="team-social">
                  <div className="play-btn">
                    <i className="far fa-plus" />
                  </div>
                  <div className="th-social">
                    <Link target="_blank" to="https://facebook.com/">
                      <i className="fab fa-facebook-f" />
                    </Link>{" "}
                    <Link target="_blank" to="https://twitter.com/">
                      <i className="fab fa-twitter" />
                    </Link>{" "}
                    <Link target="_blank" to="https://instagram.com/">
                      <i className="fab fa-instagram" />
                    </Link>{" "}
                    <Link target="_blank" to="https://linkedin.com/">
                      <i className="fab fa-linkedin-in" />
                    </Link>
                  </div>
                </div>
                <h3 className="box-title">
                  <Link to="/team-details">Henry Joshep</Link>
                </h3>
                <span className="team-desig">Product Manager</span>
                <div className="box-particle" id="team-p4" />
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#teamSlider1"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>{" "}
        <button
          data-slider-next="#teamSlider1"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
    <div className="shape-mockup" data-top={0} data-right={0}>
      <img src="assets/img/shape/tech_shape_1.png" alt="shape" />
    </div>
    <div className="shape-mockup" data-top="0%" data-left="0%">
      <img src="assets/img/shape/square_1.png" alt="shape" />
    </div>
  </section>
  <section
    className="bg-top-center space"
    data-bg-src="assets/img/bg/testi_bg_3.jpg"
  >
    <div className="container">
      <div className="title-area text-center">
        <div className="shadow-title color2">TESTIMONIALS</div>
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />{" "}
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          CUSTOMER FEEDBACK
        </span>
        <h2 className="sec-title text-white">
          What Happy Clients Says
          <br />
          <span className="text-theme">About Us?</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="testiSlider3"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-grid_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-grid_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-grid_desig">CEO at Anatora</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_4.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Md Sumon Mia</h3>
                  <p className="testi-grid_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-grid_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-grid_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-grid_desig">CEO at Anatora</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-grid">
                <div className="testi-grid_img">
                  <img
                    src="assets/img/testimonial/testi_3_4.jpg"
                    alt="Avater"
                  />
                  <div className="testi-grid_quote">
                    <img src="assets/img/icon/quote_left_3.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-grid_review">
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                  <i className="fa-solid fa-star-sharp" />
                </div>
                <div className="testi-grid_content">
                  <p className="testi-grid_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration. Globally synergize
                    resource sucking value via cutting-edge.
                  </p>
                  <h3 className="box-title">Md Sumon Mia</h3>
                  <p className="testi-grid_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#testiSlider3"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>{" "}
        <button
          data-slider-next="#testiSlider3"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
  </section>
  <section className="space-bottom" id="contact-sec">
    <div className="container">
      <div className="cta-box">
        <div className="row">
          <div className="col-lg-5">
            <div className="cta-box_img">
              <img src="assets/img/normal/customer_support.jpg" alt="Image" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="cta-box_content">
              <div className="cta-box_icon">
                <img src="assets/img/icon/call_1.svg" alt="Icon" />
              </div>
              <div className="title-area mb-35">
                <div className="shadow-title">CONSULTATION</div>
                <span className="sub-title">
                  <div className="icon-masking me-2">
                    <span
                      className="mask-icon"
                      data-mask-src="assets/img/theme-img/title_shape_2.svg"
                    />{" "}
                    <img
                      src="assets/img/theme-img/title_shape_2.svg"
                      alt="shape"
                    />
                  </div>
                  LET’S CONSULTATION
                </span>
                <h2 className="sec-title">
                  Let’s Talk About Business Solutions{" "}
                  <span className="text-theme">With Us</span>
                </h2>
              </div>
              <Link to="/contact" className="th-btn">
                CONTACT US
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="bg-top-right bg-smoke overflow-hidden space"
    id="blog-sec"
    data-bg-src="assets/img/bg/blog_bg_1.png"
  >
    <div className="container">
      <div className="title-area text-center">
        <div className="shadow-title color3">Update</div>
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />{" "}
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          NEWS &amp; ARTICLES
        </span>
        <h2 className="sec-title">
          Get Every Single Update <span className="text-theme">Blog</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="blogSlider2"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      15 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Unsatiable entreaties may collecting Power.
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      16 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />3 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Regional Manager &amp; limited time management.
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      17 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      What’s the Holding Back the It Solution Industry?
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      19 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />4 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Latin derived from Cicero's 1st-century BC
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      15 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Unsatiable entreaties may collecting Power.
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      16 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />3 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Regional Manager &amp; limited time management.
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      17 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      What’s the Holding Back the It Solution Industry?
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      19 Jan, 2024
                    </Link>{" "}
                    <Link to="/blog">
                      <i className="fal fa-comments" />4 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Latin derived from Cicero's 1st-century BC
                    </Link>
                  </h3>
                  <p className="blog-text">
                    Progressively plagiarize quality metrics for impactful data.
                    Assertively. Holisticly leverage existing magnetic.
                  </p>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />{" "}
                      By Themeholy
                    </Link>{" "}
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#blogSlider2"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>{" "}
        <button
          data-slider-next="#blogSlider2"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
    <div className="shape-mockup" data-bottom={0} data-left={0}>
      <div className="particle-2 small" id="particle-4" />
    </div>
  </section>
  <Footer1 />
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      ></path>
    </svg>
  </div>
    </React.Fragment>  )
}

export default DigitalAgency