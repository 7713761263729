import React, { useState } from 'react'

import { Link } from "react-router-dom"


export default function Navbar(){
    return <>
    <div className="cursor" />
  <div className="cursor2" />
  <div className="color-scheme-wrap active">
    <button className="switchIcon">
      <i className="fa-solid fa-palette" />
    </button>
    <h4 className="color-scheme-wrap-title">
      <i className="far fa-palette me-2" />
      Style Swicher
    </h4>
    <div className="color-switch-btns">
      <button data-color="#3E66F3">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#684DF4">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#008080">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#323F7C">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#FC3737">
        <i className="fa-solid fa-droplet" />
      </button>
      <button data-color="#8a2be2">
        <i className="fa-solid fa-droplet" />
      </button>
    </div>
    <Link
      to="https://themeforest.net/user/themeholy"
      className="th-btn text-center w-100"
    >
      <i className="fa fa-shopping-cart me-2" /> Purchase
    </Link>
  </div>
  
  <div className="sidemenu-wrapper">
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls">
        <i className="far fa-times" />
      </button>
      <div className="widget woocommerce widget_shopping_cart">
        <h3 className="widget_title">Shopping cart</h3>
        <div className="widget_shopping_cart_content">
          <ul className="woocommerce-mini-cart cart_list product_list_widget">
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_1.jpg"
                  alt="Cart Image"
                />
                Gaming Computer
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  940.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_2.jpg"
                  alt="Cart Image"
                />
                Smartphone Vivo V9
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  899.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_3.jpg"
                  alt="Cart Image"
                />
                SanDisk Flash Drive
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  756.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_4.jpg"
                  alt="Cart Image"
                />
                Smart Power Bank
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  723.00
                </span>
              </span>
            </li>
            <li className="woocommerce-mini-cart-item mini_cart_item">
              <Link to="#" className="remove remove_from_cart_button">
                <i className="far fa-times" />
              </Link>
              <Link to="#">
                <img
                  src="assets/img/product/product_thumb_1_5.jpg"
                  alt="Cart Image"
                />
                Apple Smartwatch
              </Link>
              <span className="quantity">
                1 ×
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  1080.00
                </span>
              </span>
            </li>
          </ul>
          <p className="woocommerce-mini-cart__total total">
            <strong>Subtotal:</strong>
            <span className="woocommerce-Price-amount amount">
              <span className="woocommerce-Price-currencySymbol">$</span>4398.00
            </span>
          </p>
          <p className="woocommerce-mini-cart__buttons buttons btn-wrap">
            <Link to="../cart" className="th-btn wc-forward">
              View cart
            </Link>
            <Link to="../checkout" className="th-btn checkout wc-forward">
              Checkout
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="popup-search-box d-none d-lg-block">
    <button className="searchClose">
      <i className="fal fa-times" />
    </button>
    <form action="#">
      <input type="text" placeholder="What are you looking for?" />
      <button type="submit">
        <i className="fal fa-search" />
      </button>
    </form>
  </div>
  <div className="th-menu-wrapper">
    <div className="th-menu-area text-center">
      <button className="th-menu-toggle">
        <i className="fal fa-times" />
      </button>
      <div className="mobile-logo">
        <Link className="icon-masking" to="index">
          <span data-mask-src="assets/img/hrhk-logo.png" className="mask-icon" />
          <img src="assets/img/hrhk-logo.png" alt="Webteck" />
        </Link>
      </div>
      <div className="th-mobile-menu">
        <ul>
          <li className="menu-item-has-children mega-menu-wrap">
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li className="menu-item-has-children">
            <Link to="#">Services</Link>
            <ul className="sub-menu">
                  <li>
                    <Link to="/home-web-development">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/home-app-landing">App Development</Link>
                  </li>
                  <li>
                    <Link to="/home-software-company">Software Company</Link>
                  </li>
                  <li>
                    <Link to="/home-it-solution">IT Solution</Link>
                  </li>
            <li>
                    <Link to="/">Digital Agency</Link>
                  </li>
            </ul>
          </li>
          <li className="menu-item-has-children">
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <header className="th-header header-layout1">
    <div className="header-top">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
          <div className="col-auto d-none d-lg-block">
            <div className="header-links">
              <ul>
                <li>
                  <i className="fas fa-map-location" />
                  4071 2nd Floor, Sec - 49, FBD, Haryana
                </li>
                <li>
                  <i className="fas fa-phone" />
                  <Link to="tel:+1539873657">+91 8448504477</Link>
                </li>
                <li>
                  <i className="fas fa-envelope" />
                  <Link to="mailto:info@webteck.com">info@hrhktechnology.com</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto">
            <div className="header-social">
              <span className="social-title">Follow Us On : </span>
              <Link to="https://www.facebook.com/">
                <i className="fab fa-facebook-f" />
              </Link>
              <Link to="https://www.twitter.com/">
                <i className="fab fa-twitter" />
              </Link>
              <Link to="https://www.linkedin.com/">
                <i className="fab fa-linkedin-in" />
              </Link>
              <Link to="https://www.instagram.com/">
                <i className="fab fa-instagram" />
              </Link>
              <Link to="https://www.youtube.com/">
                <i className="fab fa-youtube" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sticky-wrapper">
      <div className="menu-area">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="header-logo">
                <Link className="icon-masking" to="index">
                  <img src="assets/img/hrhk-logo.png" alt="HRHK" />
                </Link>
              </div>
            </div>
            <div className="col-auto">
              <nav className="main-menu d-none d-lg-inline-block">
                <ul>
                  <li className=" mega-menu-wrap">
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="#">Services</Link>
                    <ul className="sub-menu">
                          <li>
                            <Link to="/home-web-development">
                              Web Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-app-landing">App Development</Link>
                          </li>
                          <li>
                            <Link to="/home-software-company">
                              Software Company
                            </Link>
                          </li>
                          <li>
                            <Link to="/home-it-solution">IT Solution</Link>
                          </li>
                    <li>
                            <Link to="/">Digital Agency</Link>
                          </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </nav>
              <div className="header-button">
                <button
                  type="button"
                  className="icon-btn sideMenuToggler d-inline-block d-lg-none"
                >
                  <i className="fal fa-shopping-cart" />
                  <span className="badge">5</span>
                </button>
                <button
                  type="button"
                  className="th-menu-toggle d-inline-block d-lg-none"
                >
                  <i className="far fa-bars" />
                </button>
              </div>
            </div>
            <div className="col-auto d-none d-lg-block">
              <div className="header-button">
                <Link to="/contact" className="th-btn style3 shadow-none">
                  Make Appointment
                  <i className="fas fa-arrow-right ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="logo-bg" />
      </div>
    </div>
  </header>
    </>
}