import React, { useState } from 'react'
import TabTitle from '../Utiles/FunctionTitle'
import { Link } from 'react-router-dom'
import Footer1 from '../Common/Footer1'


const AppLanding = () => {
  TabTitle('App Development - HRHK Technology')

  return (
    <div className="theme-orange">
    <div className="th-hero-wrapper hero-8" id="hero">
      <div className="hero-inner">
        <div
          className="th-hero-bg"
          data-bg-src="assets/img/hero/hero_bg_8_1.png"
        />
        <div className="container th-container4">
          <div className="row justify-content-center align-items-center">
            <div className="col-xxl-5 col-xl-6">
              <div className="hero-style8">
                <span className="sub-title">Powerful Digital Mobile Apps</span>
                <h1 className="hero-title">
                  <span className="text-theme">
                    Web<span className="fw-normal">Teck</span>
                  </span>
                  Where Efficiency Meets Innovation
                </h1>
                <p className="hero-text">
                  We provide many features that you can use cheaply and easily.
                  Try it now and get an interesting promo
                </p>
                <div className="btn-group mt-30">
                  <Link to="/service" className="th-btn style7 style-radius">
                    <img
                      src="assets/img/theme-img/play-store-btn.png"
                      alt="img"
                    />
                  </Link>
                  <Link to="/service" className="th-btn style7 style-radius">
                    <img src="assets/img/theme-img/apple-btn.png" alt="img" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6">
              <div className="th-hero-thumb">
                <img src="assets/img/hero/hero_img_8_1.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="brand-sec4 overflow-hidden space">
      <div className="container th-container4">
        <div className="title-area mb-60 text-center">
          <h3 className="brand-title mt-n2">36k+ Installation And Featured On</h3>
        </div>
        <div className="slider-area text-center">
          <div
            className="swiper th-slider brand-slider4"
            id="brandSlider4"
            data-slider-options='{"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"4"},"1200":{"slidesPerView":"5"},"1400":{"slidesPerView":"6"}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_1.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_2.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_3.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_4.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_5.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_6.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_1.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_2.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_3.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_4.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_5.svg" alt="Brand Logo" />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="brand-box">
                  <img src="assets/img/brand/brand_3_6.svg" alt="Brand Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-hidden space-bottom" id="service-sec">
      <div className="container th-container4">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="title-area text-center">
              <span className="sub-title">Awesome Feature</span>
              <h2 className="sec-title">Our Awesome app Features</h2>
            </div>
          </div>
        </div>
        <div className="row gy-4 justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 feature-card-wrap2">
            <div className="feature-card style2">
              <div className="feature-card-icon">
                <img src="assets/img/icon/feature_card_8_1.svg" alt="icon" />
              </div>
              <h3 className="box-title">
                <Link to="service-details%2chtml">
                  Note Powerful and Security
                </Link>
              </h3>
              <p className="feature-card_text">
                Incorporate testimonials customer reviews, or social media feeds
                to build trust and credibility.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 feature-card-wrap2">
            <div className="feature-card style2">
              <div className="feature-card-icon">
                <img src="assets/img/icon/feature_card_8_2.svg" alt="icon" />
              </div>
              <h3 className="box-title">
                <Link to="service-details%2chtml">
                  Easy Sort, Classify and Search
                </Link>
              </h3>
              <p className="feature-card_text">
                Testimonials incorporate customer reviews, or social media feeds
                to build trust and credibility.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 feature-card-wrap2">
            <div className="feature-card style2">
              <div className="feature-card-icon">
                <img src="assets/img/icon/feature_card_8_3.svg" alt="icon" />
              </div>
              <h3 className="box-title">
                <Link to="service-details%2chtml">Collaboration and Share</Link>
              </h3>
              <p className="feature-card_text">
                Customer testimonials incorporate reviews, or social media feeds
                to build trust and credibility.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 feature-card-wrap2">
            <div className="feature-card style2">
              <div className="feature-card-icon">
                <img src="assets/img/icon/feature_card_8_4.svg" alt="icon" />
              </div>
              <h3 className="box-title">
                <Link to="service-details%2chtml">
                  Share your Data to anyone
                </Link>
              </h3>
              <p className="feature-card_text">
                Reviews incorporate testimonials customer, or social media feeds
                to build trust and credibility.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="about-sec-8 overflow-hidden th-radius3"
      data-bg-src="assets/img/bg/about_bg_8_1.png"
      id="about-sec"
    >
      <div className="container th-container4">
        <div className="row justify-content-between flex-row-reverse">
          <div className="col-xl-6">
            <div className="space">
              <div className="title-area mb-40">
                <span className="sub-title">About Our App</span>
                <h2 className="sec-title">
                  Built for the next generation
                  <br className="d-xxl-block d-none" />
                  of small business
                </h2>
                <p>
                  Incorporate visually appealing elements such as high-quality
                  images, videos, or graphics that resonate with the target
                  audience and complement the overall design of the page. Ensure
                  that the landing page is optimized for various devices.
                </p>
              </div>
              <div className="two-column mb-50 list-center ms-0">
                <div className="checklist style6">
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" /> Easy Customize
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Free updates
                    </li>
                  </ul>
                </div>
                <div className="checklist style6">
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" /> Fully Responsive
                    </li>
                    <li>
                      <i className="fas fa-check-circle" /> Ultimate support
                    </li>
                  </ul>
                </div>
              </div>
              <div className="btn-wrap">
                <Link to="/contact" className="th-btn style-radius">
                  Get Started For Free 14 Days
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6 align-self-end">
            <div className="img-box10 text-center mb-xl-0 mt-xl-0 mt-n4">
              <div className="img1">
                <img src="assets/img/normal/about_8_1.jpg" alt="About" />
                <div className="img2 jump">
                  <img src="assets/img/normal/about_8_2.jpg" alt="About" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="space-top">
      <div className="why-sec-4" data-bg-src="assets/img/bg/why_bg_8.png">
        <div className="container th-container4">
          <div className="row justify-content-center">
            <div className="col-xl-5">
              <div className="title-area text-center">
                <span className="sub-title">WHY CHOOSE APP</span>
                <h2 className="sec-title">
                  Why Web<span className="text-theme fw-medium">Teck</span> Is The
                  Right Choice For You
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="why-feature-wrap-4-1">
                <div className="about-feature style3">
                  <div className="about-feature_icon">
                    <img src="assets/img/icon/why-8-1.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h3 className="about-feature_title">
                      Extensions &amp; Addons
                    </h3>
                    <p className="about-feature_text">
                      Integrate analytics tools such as Google Analytics to track
                      visitor behavior.
                    </p>
                  </div>
                </div>
                <div className="about-feature style3">
                  <div className="about-feature_icon">
                    <img src="assets/img/icon/why-8-2.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h3 className="about-feature_title">Fast &amp; Easy</h3>
                    <p className="about-feature_text">
                      Integrate analytics tools such as Google Analytics to track
                      visitor behavior.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-3">
              <div className="why-feature-wrap-4-2">
                <div className="about-feature style3">
                  <div className="about-feature_icon">
                    <img src="assets/img/icon/why-8-3.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h3 className="about-feature_title">Friendly Interface</h3>
                    <p className="about-feature_text">
                      Integrate analytics tools such as Google Analytics to track
                      visitor behavior.
                    </p>
                  </div>
                </div>
                <div className="about-feature style3">
                  <div className="about-feature_icon">
                    <img src="assets/img/icon/why-8-4.svg" alt="icon" />
                  </div>
                  <div className="media-body">
                    <h3 className="about-feature_title">Saved Replies</h3>
                    <p className="about-feature_text">
                      Integrate analytics tools such as Google Analytics to track
                      visitor behavior.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-2">
              <div className="why-thumb-4">
                <img src="assets/img/normal/why_8_1.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="counter-area-6 overflow-hidden bg-theme">
      <div className="container th-container4">
        <div className="row justify-content-md-between justify-content-center gy-40">
          <div className="col-xl-auto col-sm-6">
            <div className="counter-card6">
              <h3 className="box-number">
                <span className="counter-number">96</span>k+
              </h3>
              <div className="media-body">
                <p className="counter-text mb-n2">
                  Customers visit Webteck app every months
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-auto col-sm-6">
            <div className="counter-card6">
              <h3 className="box-number">
                <span className="counter-number">74</span>k+
              </h3>
              <div className="media-body">
                <p className="counter-text mb-n2">
                  Total Members of Webteck app users
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-auto col-sm-6">
            <div className="counter-card6">
              <h3 className="box-number">
                <span className="counter-number">100</span>k
              </h3>
              <div className="media-body">
                <p className="counter-text mb-n2">
                  Total downloaded of our Webteck app
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-auto col-sm-6">
            <div className="counter-card6">
              <h3 className="box-number">
                <span className="counter-number">96</span>%
              </h3>
              <div className="media-body">
                <p className="counter-text mb-n2">
                  Satisfaction rate from our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="space">
      <div className="container th-container4">
        <div className="row gy-40 align-items-center flex-row-reverse">
          <div className="col-lg-6 text-lg-end">
            <div className="img-box11">
              <div className="img1">
                <img src="assets/img/normal/download-8-1.png" alt="img" />
              </div>
              <div className="img2 jump">
                <img src="assets/img/normal/download-8-1.1.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="title-area">
              <span className="sub-title">Made Easy Way</span>
              <h2 className="sec-title">
                Keep track of your expenses
                <br className="d-xl-block d-none" />
                by the app
              </h2>
              <p>
                Incorporate visually appealing elements such as high-quality
                images, videos, or graphics that resonate with the target audience
                and complement the overall design of the page. Ensure that the
                landing page is optimized for various devices.
              </p>
              <div className="two-column mb-50 mt-30">
                <div className="checklist style6">
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" />
                      <div className="details">
                        <h6>Customizable App</h6>
                        <p className="mb-n2">
                          Ensure that the landing page is optimized for various
                          devices.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="checklist style6">
                  <ul>
                    <li>
                      <i className="fas fa-check-circle" />
                      <div className="details">
                        <h6>Statistic Retroactively</h6>
                        <p className="mb-n2">
                          Ensure that the landing page is optimized for various
                          devices.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="btn-wrap">
              <Link to="/contact" className="th-btn style-radius">
                Get Started For Free 14 Days
              </Link>
              <Link
                to="/contact"
                className="th-btn style-border2 style-radius"
              >
                <i className="far fa-cloud-arrow-down me-2" />
                Download app
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="space-bottom">
      <div className="container th-container4">
        <div className="row gy-40 align-items-center">
          <div className="col-lg-6">
            <div className="img-box12">
              <div className="img1">
                <img src="assets/img/normal/download-8-2.png" alt="img" />
              </div>
              <div className="img2 jump">
                <img src="assets/img/normal/download-8-2.1.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="title-area mb-40">
              <span className="sub-title">Easy User Experience</span>
              <h2 className="sec-title">
                Better cash control with
                <br className="d-xl-block d-none" />
                Web<span className="text-theme fw-normal">Teck</span>
                Flow
              </h2>
              <p>
                Provide a brief overview of the app's purpose and key features.
                This introduction should capture the attention of visitors and
                compel them to explore further. Highlight the most important
                features and functionalities of the app.
              </p>
              <p>
                Use concise descriptions or bullet points to emphasize what sets
                your app apart from others in the market.
              </p>
            </div>
            <div className="btn-wrap">
              <Link to="/contact" className="th-btn style-radius">
                Get Started For Free 14 Days
              </Link>
              <Link
                to="/contact"
                className="th-btn style-border2 style-radius"
              >
                <i className="far fa-cloud-arrow-down me-2" />
                Download app
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      className="screenshot-sec-1 overflow-hidden space-top"
      data-bg-src="assets/img/bg/screenshot_bg_8.png"
      id="project-sec"
    >
      <div className="container th-container4">
        <div className="title-area text-center">
          <span className="sub-title text-white">Screenshots</span>
          <h2 className="sec-title text-white">Let’s See Our App Screenshots</h2>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="slider-area text-center">
          <div
            className="swiper th-slider screen-slider1"
            id="screenSlider1"
            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2","centeredSlides":"true"},"768":{"slidesPerView":"4","centeredSlides":"true"},"992":{"slidesPerView":"4","centeredSlides":"true"},"1200":{"slidesPerView":"4","centeredSlides":"true"},"1400":{"slidesPerView":"6","centeredSlides":"true"}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_1.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_2.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_3.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_4.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_5.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_6.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_7.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_1.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_2.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_3.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_4.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_5.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_6.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
              <div className="swiper-slide">
                <Link to="/about" className="screetshot-thumb">
                  <img
                    src="assets/img/normal/screenshot-slider_8_7.png"
                    alt="Brand Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="slider-pagination">`</div>
          </div>
        </div>
      </div>
    </div>
    <section className="space">
      <div className="container th-container4">
        <div className="title-area text-center">
          <span className="sub-title">Our Pricing</span>
          <h2 className="sec-title">Choose The Plans That Suits You!</h2>
          <p>Choose a plan that suits your business needs</p>
          <div className="pricing-tabs">
            <div className="switch-area">
              <label
                className="toggler toggler--is-active ms-0"
                id="filt-monthly"
              >
                Monthly
              </label>
              <div className="toggle">
                <input type="checkbox" id="switcher" className="check" />
                <b className="b switch" />
              </div>
              <label className="toggler" id="filt-yearly">
                Yearly
              </label>
            </div>
            <div className="discount-tag">
              <svg
                width={54}
                height={41}
                viewBox="0 0 54 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.5389 7.99353C15.4629 8.44111 15.3952 8.82627 15.3583 9.02966C15.1309 10.2666 14.942 13.4078 14.062 15.5433C13.3911 17.1727 12.3173 18.2233 10.6818 17.8427C9.19525 17.4967 8.26854 16.0251 7.82099 13.9916C6.85783 9.61512 8.00529 2.6265 8.90147 0.605294C8.99943 0.384693 9.25826 0.284942 9.48075 0.382666C9.70224 0.479891 9.80333 0.737018 9.70537 0.957619C8.84585 2.89745 7.75459 9.6061 8.67913 13.8076C9.04074 15.4498 9.68015 16.7144 10.881 16.9937C12.0661 17.2698 12.7622 16.3933 13.2485 15.2121C14.1054 13.134 14.273 10.0757 14.4938 8.87118C14.6325 8.11613 15.0798 5.22149 15.1784 4.9827C15.3016 4.68358 15.5573 4.69204 15.641 4.70108C15.7059 4.708 16.0273 4.76322 16.0423 5.15938C16.2599 10.808 20.5327 19.3354 26.8096 25.0475C33.0314 30.7095 41.2522 33.603 49.4783 28.0026C49.6784 27.8669 49.9521 27.9178 50.0898 28.1157C50.2269 28.3146 50.1762 28.5863 49.9762 28.7219C41.3569 34.5897 32.7351 31.6217 26.217 25.6902C20.7234 20.6913 16.7462 13.5852 15.5389 7.99353Z"
                  fill="var(--theme-color)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M49.2606 28.5952C48.2281 28.5096 47.1974 28.4571 46.1708 28.2919C43.4358 27.8522 40.6863 26.8206 38.4665 25.1551C38.2726 25.0089 38.2345 24.7355 38.3799 24.5438C38.5267 24.3517 38.8021 24.3145 38.9955 24.4592C41.1013 26.0411 43.7143 27.0136 46.3092 27.4305C47.4844 27.6191 48.6664 27.6581 49.8489 27.7714C49.9078 27.7778 50.4232 27.8114 50.53 27.8482C50.7793 27.9324 50.8288 28.1252 50.8402 28.2172C50.8506 28.2941 50.8446 28.3885 50.7944 28.4939C50.7528 28.5801 50.6349 28.7253 50.4357 28.886C49.7992 29.4029 48.1397 30.3966 47.8848 30.5884C44.9622 32.7862 42.6161 35.3187 40.0788 37.9235C39.9097 38.0958 39.6311 38.1004 39.4566 37.9332C39.2821 37.766 39.2778 37.49 39.4459 37.3172C42.0151 34.6792 44.3946 32.1179 47.353 29.8939C47.5278 29.7615 48.5366 29.0813 49.2606 28.5952Z"
                  fill="var(--theme-color)"
                />
              </svg>
              Save 35%
            </div>
          </div>
        </div>
        <div id="monthly" className="wrapper-full">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="price-box style2 th-ani">
                <div className="price-title-wrap">
                  <img src="assets/img/icon/pricing_card_7_1.svg" alt="icon" />
                  <div className="media-body">
                    <p className="subtitle">FREE</p>
                    <h3 className="box-title">Basic Plan</h3>
                  </div>
                </div>
                <p className="price-box_text">Perfect plan to get started</p>
                <h4 className="price-box_price">
                  $0<span className="duration">/month</span>
                </h4>
                <p className="price-box_text">
                  A free plan grants you access to some cool features of Spend.In.
                </p>
                <div className="price-box_content">
                  <div className="available-list">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" />
                        Sync accross device
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />5 workspace
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Collaborate with 5 user
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-xmark" />
                        Sharing permission
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-xmark" />
                        Admin tools
                      </li>
                      <li className="unavailable">
                        <i className="fas fa-circle-xmark" />
                        100+ integrations
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn btn-fw style-radius">
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-box style2 th-ani">
                <span className="offer-tag">
                  <span className="tag">Popular</span>
                </span>
                <div className="price-title-wrap">
                  <img src="assets/img/icon/pricing_card_7_2.svg" alt="icon" />
                  <div className="media-body">
                    <p className="subtitle">ULTIMATE</p>
                    <h3 className="box-title">Standard Plan</h3>
                  </div>
                </div>
                <p className="price-box_text">Best suits for great company!</p>
                <h4 className="price-box_price">
                  $36<span className="duration">/month</span>
                </h4>
                <p className="price-box_text">
                  If you a finance manager at big company, this plan is a perfect
                  match.
                </p>
                <div className="price-box_content">
                  <div className="available-list">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" />
                        Everything in Free Plan
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        30 workspace
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Collaborative workspace
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Sharing permission
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Admin tools
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        100+ integrations
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn btn-fw style-radius">
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="price-box style2 th-ani">
                <div className="price-title-wrap">
                  <img src="assets/img/icon/pricing_card_7_3.svg" alt="icon" />
                  <div className="media-body">
                    <p className="subtitle">PRO</p>
                    <h3 className="box-title">Premium Plan</h3>
                  </div>
                </div>
                <p className="price-box_text">Perfect plan for professionals!</p>
                <h4 className="price-box_price">
                  $66<span className="duration">/month</span>
                </h4>
                <p className="price-box_text">
                  For professional only! Start arranging your expenses with our
                  best templates.
                </p>
                <div className="price-box_content">
                  <div className="available-list">
                    <ul>
                      <li>
                        <i className="fas fa-circle-check" />
                        Everything in Pro Plan
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Unlimited workspace
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Collaborative workspace
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Sharing permission
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        Admin tools
                      </li>
                      <li>
                        <i className="fas fa-circle-check" />
                        100+ integrations
                      </li>
                    </ul>
                  </div>
                  <Link to="/contact" className="th-btn btn-fw style-radius">
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="yearly" className="wrapper-full hide">
          <div className="row justify-content-center">
            <br />
            <b>Warning</b>: include(sections/pricing-v4.1.php): Failed to open
            stream: No such file or directory in
            <b>E:\web-dev\angfuzsoft\html\webtek-html\build\inc\functions.php</b>
            on line <b>20</b>
            <br />
            <br />
            <b>Warning</b>: include(): Failed opening 'sections/pricing-v4.1.php'
            for inclusion (include_path='E:\web-dev\server\php\PEAR') in
            <b>E:\web-dev\angfuzsoft\html\webtek-html\build\inc\functions.php</b>
            on line <b>20</b>
            <br />
          </div>
        </div>
      </div>
    </section>
    <div
      className="about-sec-8 overflow-hidden th-radius3"
      data-bg-src="assets/img/bg/download_bg_8-3.png"
    >
      <div className="container th-container4">
        <div className="row justify-content-between">
          <div className="col-xl-6">
            <div className="space">
              <div className="title-area mb-30">
                <span className="sub-title">Project Management App</span>
                <h2 className="sec-title mb-4">
                  Download our app and start your free trail to get started today!
                </h2>
                <p>
                  Clearly communicate the benefits of using the app. Explain how
                  it solves a problem or improves the user's life in some way.
                  Focus on the value proposition and what makes the app worth
                  downloading. Include a prominent call-to-action button that
                  encourages visitors to download the app or sign up for a free
                  trial.
                </p>
                <p>
                  Specify which platforms the app is available on (e.g., iOS,
                  Android) and provide links to download from the respective app
                  stores.
                </p>
              </div>
              <div className="btn-group mt-30">
                <Link to="/service" className="th-btn style7 style-radius">
                  <img src="assets/img/theme-img/play-store-btn.png" alt="img" />
                </Link>
                <Link to="/service" className="th-btn style7 style-radius">
                  <img src="assets/img/theme-img/apple-btn.png" alt="img" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-6 align-self-end">
            <div className="thumb text-center mt-xl-0 mt-n5">
              <div className="img1">
                <img src="assets/img/normal/download-8-3.png" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="space">
      <div className="container">
        <div className="title-area text-center">
          <span className="sub-title">Customer Feedbacks</span>
          <h2 className="sec-title">Loved by our customer worldwide</h2>
        </div>
        <div className="slider-area testi-grid2-area">
          <div className="testi-indicator">
            <div
              className="swiper th-slider testi-grid2-thumb"
              data-slider-options='{"effect":"slide","slidesPerView":"5","spaceBetween":13,"loop":true,"breakpoints":{"0":{"slidesPerView":4},"576":{"slidesPerView":"5"}}}'
              data-slider-tab="#testiSlide1"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_1_1.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_1_2.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_1_3.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_1_4.png" alt="Image" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="box-img">
                    <img src="assets/img/testimonial/testi_1_5.png" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper th-slider"
            id="testiSlide7"
            data-slider-options='{"effect":"slide","loop":true,"thumbs":{"swiper":".testi-grid2-thumb"},"breakpoints":{"0":{"autoHeight":true},"576":{"autoHeight":false}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testi-card2">
                  <p className="testi-card2_text">
                    I've had the pleasure of working with Webteck for the past
                    year, and I can confidently say that they have been
                    instrumental in the success of our IT initiatives. As a
                    startup, we needed a technology partner that could not only
                    meet our immediate needs but also grow with us as we expand
                    our business. Webteck has exceeded our expectations on every
                    front.
                  </p>
                  <div className="testi-card2_profile">
                    <div className="testi-card2_content">
                      <h3 className="box-title">Michel Jane Alum</h3>
                      <span className="testi-card2_desig">CTO of Portfolio</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-card2">
                  <p className="testi-card2_text">
                    If you have specific questions about gathering or analyzing
                    customer feedback, or if you're looking for general
                    information on the importance of customer feedback, feel free
                    to ask. Additionally, if you have a sample of customer
                    feedback that you'd like assistance with, you can share it,
                    and I can provide insights or suggestions based on you provide
                  </p>
                  <div className="testi-card2_profile">
                    <div className="testi-card2_content">
                      <h3 className="box-title">Alex Michel</h3>
                      <span className="testi-card2_desig">Founder CEO</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-card2">
                  <p className="testi-card2_text">
                    It sounds like you're expressing positive feedback or a
                    statement about a product or service being loved by customers
                    worldwide. If this is a statement you want to share or
                    discuss, it's great to highlight positive feedback! Customer
                    satisfaction and positive testimonials can be powerful assets
                    for a product or services businesses I'm here to help!
                  </p>
                  <div className="testi-card2_profile">
                    <div className="testi-card2_content">
                      <h3 className="box-title">Jenny Wilson</h3>
                      <span className="testi-card2_desig">Project Manager</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-card2">
                  <p className="testi-card2_text">
                    If you have a specific question or if there's more information
                    you'd like to provide or discuss regarding your product or
                    service being loved by customers worldwide, feel free to share
                    more details. Whether you're looking for advice on how to
                    leverage this positive feedback, strategies to maintain
                    customer satisfaction, or any other related
                  </p>
                  <div className="testi-card2_profile">
                    <div className="testi-card2_content">
                      <h3 className="box-title">Savannah Nguyen</h3>
                      <span className="testi-card2_desig">UI/UX Designer</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-card2">
                  <p className="testi-card2_text">
                    It's fantastic to hear that your product or service is loved
                    by customers worldwide! Positive customer feedback is a strong
                    indicator of satisfaction and can contribute significantly to
                    the success and reputation of a business. If you're looking to
                    capitalize on this positive sentiment, here are a few as we
                    expand our business topic, Positive customer feedback I'm here
                  </p>
                  <div className="testi-card2_profile">
                    <div className="testi-card2_content">
                      <h3 className="box-title">Cameron Williamson</h3>
                      <span className="testi-card2_desig">Disaster Recovery</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              data-slider-prev="#testiSlide7"
              className="slider-arrow slider-prev"
            >
              <i className="far fa-arrow-left" />
            </button>
            <button
              data-slider-next="#testiSlide7"
              className="slider-arrow slider-next"
            >
              <i className="far fa-arrow-right" />
            </button>
          </div>
          <div className="testi-line" />
        </div>
      </div>
    </section>
    <Footer1 />
    <div className="scroll-top">
      <svg
        className="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            transition: "stroke-dashoffset 10ms linear 0s",
            strokeDasharray: "307.919, 307.919",
            strokeDashoffset: "307.919"
          }}
        />
      </svg>
    </div>
   
  </div> 
  
  )
}

export default AppLanding