import React from "react";
import TabTitle from "../Utiles/FunctionTitle";
import { Link } from 'react-router-dom'
import Footer1 from "../Common/Footer1";

const SoftwareCompany = () => {
  TabTitle('Software Company - HRHK Technology')

  return (
    <div className="gradient-body">
         <div className="th-hero-wrapper hero-1" id="hero">
            <div className="hero-img tilt-active">
              <img src="assets/img/hero/hero_img_1_1.png" alt="Hero Image" />
            </div>
            <div className="container">
              <div className="hero-style1">
                <span className="hero-subtitle">
                  Solution For Your Business
                </span>
                <h1 className="hero-title">Providing Technology</h1>
                <h1 className="hero-title">
                  For Smart{" "}
                  <span className="text-theme fw-medium">IT Solution</span>
                </h1>
                <p className="hero-text">
                  Rapidiously streamline revolutionary networks with technically
                  sound deliverables. Authoritatively integrate installed base
                  web-readiness. Quickly maximize superior process.
                </p>
                <div className="btn-group">
                  <Link to="/about" className="th-btn">
                    ABOUT US
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                  <div className="call-btn">
                    <Link
                      to="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                      className="play-btn popup-video"
                    >
                      <i className="fas fa-play" />
                    </Link>
                    <div className="media-body">
                      <Link
                        to="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                        className="btn-title popup-video"
                      >
                        Watch Our Story
                      </Link>
                      <span className="btn-text">Subscribe Now</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-shape1">
              <img src="assets/img/hero/hero_shape_1_1.svg" alt="shape" />
            </div>
            <div className="hero-shape2">
              <img src="assets/img/hero/hero_shape_1_2.svg" alt="shape" />
            </div>
            <div className="hero-shape3">
              <img src="assets/img/hero/hero_shape_1_3.svg" alt="shape" />
            </div>
          </div>
          <section className="about-sec-v4 space-bottom" id="about-sec">
            <div className="container">
              <div className="row gy-4 justify-content-center">
                <div className="col-xl-4 col-md-6">
                  <div className="feature-card">
                    <div className="shape-icon">
                      <img src="assets/img/icon/feature_card_1.png" alt="icon" />
                    </div>
                    <h3 className="box-title">Data Management Service</h3>
                    <p className="feature-card_text">
                      Intrinsicly maximize best-of-breed strategic theme areas
                      whereas premium alignments. Collaboratively transition
                      client.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="feature-card">
                    <div className="shape-icon">
                      <img src="assets/img/icon/feature_card_2.png" alt="icon" />
                    </div>
                    <h3 className="box-title">IT Strategy &amp; Consultancy</h3>
                    <p className="feature-card_text">
                      Intrinsicly maximize best-of-breed strategic theme areas
                      whereas premium alignments. Collaboratively transition
                      client.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="feature-card">
                    <div className="shape-icon">
                      <img src="assets/img/icon/feature_card_3.png" alt="icon" />
                    </div>
                    <h3 className="box-title">World Class Support</h3>
                    <p className="feature-card_text">
                      Intrinsicly maximize best-of-breed strategic theme areas
                      whereas premium alignments. Collaboratively transition
                      client.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container space-top">
              <div className="row align-items-center">
                <div className="col-lg-5 mb-30 mb-lg-0">
                  <div className="img-box6">
                    <div className="img1">
                      <img src="assets/img/normal/about_4_1.png" alt="About" />
                    </div>
                    <div className="shape1">
                      <img src="assets/img/normal/about_4_2.png" alt="About" />
                    </div>
                    <div className="shape2">
                      <img src="assets/img/normal/about_4_3.png" alt="About" />
                    </div>
                    <div className="color-animate" />
                  </div>
                </div>
                <div className="col-lg-7 text-lg-start text-center">
                  <div className="ps-xxl-5">
                    <div className="title-area mb-35">
                      <span className="sub-title">About Company</span>
                      <h2 className="sec-title">
                        We've Been Thriving for 25 Years.
                      </h2>
                    </div>
                    <p className="mt-n2 mb-25">
                      Continually harness backward-compatible initiatives and
                      synergistic content. Objectively strategize cutting edge
                      niches with collaborative synergy. Globally pontificate
                      e-business processes through orthoonal web readiness
                      enhance backend value.
                    </p>
                    <div className="checklist style4 mb-40 list-center">
                      <ul>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" />
                          Dramatically re-engineer value added IT systems via
                          mission
                        </li>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" /> Website
                          &amp; Mobile application design &amp; Development
                        </li>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" />
                          Professional User Experince &amp; Interface
                          researching
                        </li>
                      </ul>
                    </div>
                    <Link to="/about" className="th-btn">
                      ABOUT MORE
                      <i className="fa-regular fa-arrow-right ms-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="" id="service-sec">
            <div className="round-container gr-bg3 space">
              <div className="container">
                <div className="row gy-40 justify-content-between space-bottom">
                  <div className="col-6 col-lg-auto">
                    <div className="counter-card">
                      <div className="icon">
                        <img src="assets/img/icon/counter_2_1.png" alt="Icon" />
                      </div>
                      <div className="media-body">
                        <h2 className="counter-card_number text-title">
                          <span className="counter-number">986</span>+
                        </h2>
                        <p className="counter-card_text text-body">
                          Finished Project
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-auto">
                    <div className="counter-card">
                      <div className="icon">
                        <img src="assets/img/icon/counter_2_2.png" alt="Icon" />
                      </div>
                      <div className="media-body">
                        <h2 className="counter-card_number text-title">
                          <span className="counter-number">896</span>+
                        </h2>
                        <p className="counter-card_text text-body">
                          Happy Clients
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-auto">
                    <div className="counter-card">
                      <div className="icon">
                        <img src="assets/img/icon/counter_2_3.png" alt="Icon" />
                      </div>
                      <div className="media-body">
                        <h2 className="counter-card_number text-title">
                          <span className="counter-number">396</span>+
                        </h2>
                        <p className="counter-card_text text-body">
                          Skilled Experts
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-auto">
                    <div className="counter-card">
                      <div className="icon">
                        <img src="assets/img/icon/counter_2_4.png" alt="Icon" />
                      </div>
                      <div className="media-body">
                        <h2 className="counter-card_number text-title">
                          <span className="counter-number">496</span>+
                        </h2>
                        <p className="counter-card_text text-body">
                          Honorable Awards
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="title-area text-center">
                  <span className="sub-title">OUR SERVICES</span>
                  <h2 className="sec-title">We Provide Exclusive Services</h2>
                </div>
                <div className="slider-area">
                  <div
                    className="swiper th-slider has-shadow"
                    data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_1.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">Web Development</Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_2.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">UI/UX Design</Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_3.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">
                                Digital Marketing
                              </Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_4.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">
                                Business Analysis
                              </Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_1.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">Web Development</Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_2.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">UI/UX Design</Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_3.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">
                                Digital Marketing
                              </Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="service-3d">
                          <div className="service-3d_icon">
                            <img src="assets/img/icon/service_3d_4.png" alt="Icon" />
                          </div>
                          <div className="service-3d_content">
                            <h3 className="box-title">
                              <Link to="/service-details">
                                Business Analysis
                              </Link>
                            </h3>
                            <p className="service-3d_text">
                              Intrinsicly redefine competitive e-business before
                              adaptive potentialiti. Professionally build
                              progressive users with.
                            </p>
                            <Link to="/service-details" className="th-btn">
                              Read More
                              <i className="fas fa-arrow-right ms-2" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="">
            <div className="container space">
              <div className="row align-items-center flex-row-reverse">
                <div className="col-lg-6 mb-30 mb-lg-0">
                  <div className="img-box7">
                    <div className="img1">
                      <img src="assets/img/normal/why_3_1.png" alt="Why" />
                    </div>
                    <div className="shape1">
                      <img src="assets/img/normal/why_3_2.png" alt="About" />
                    </div>
                    <div className="shape2">
                      <img src="assets/img/normal/why_3_3.png" alt="About" />
                    </div>
                    <div className="color-animate" />
                  </div>
                </div>
                <div className="col-lg-6 text-lg-start text-center">
                  <div className="title-area mb-35">
                    <span className="sub-title">WHY CHOOSE US</span>
                    <h2 className="sec-title">
                      You Get IT &amp; Business Solution
                    </h2>
                  </div>
                  <p className="mt-n2 mb-30">
                    Dynamically brand plug-and-play action items for extensive
                    leadership. Dramatically seize prospective content through
                    seamless collaboration. Quickly leverage existing 24/7
                    portals via scalable alignments.
                  </p>
                  <div className="two-column mb-40 list-center">
                    <div className="checklist style2">
                      <ul>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" /> Big Data
                          Analysis
                        </li>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" /> 24/7
                          Online Support
                        </li>
                      </ul>
                    </div>
                    <div className="checklist style2">
                      <ul>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" /> High
                          Quality Sicurity
                        </li>
                        <li>
                          <img src="assets/img/icon/check_1.png" alt="icon" /> Virtual
                          Support Team
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Link to="/about" className="th-btn">
                    LEARN MORE
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="shape-mockup" data-bottom={0} data-right={0}>
              <div className="particle-3" id="particle-4" />
            </div>
          </div>
          <section className="" id="project-sec">
            <div className="round-container gr-bg4 space">
              <div className="container">
                <div className="row justify-content-lg-between justify-content-center align-items-center">
                  <div className="col-lg-5 mb-n2 mb-lg-0">
                    <div className="title-area text-center text-lg-start">
                      <span className="sub-title">CASE STUDY</span>
                      <h2 className="sec-title">Our Portfolio / Works</h2>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="sec-btn">
                      <Link to="/project" className="th-btn">
                        VIEW ALL PORJECTS
                        <i className="fa-regular fa-arrow-right ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="slider-area">
                  <div
                    className="swiper th-slider has-shadow"
                    id="projectSlider3"
                    data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_1.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  IT Consultency
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_1.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_2.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  Web Development
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_2.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_3.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  Website Design
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_3.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_4.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  SEO Optimization
                                </Link>
                              </h3>
                              <p className="project-subtitle">Marketing</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_4.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_1.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  IT Consultency
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_1.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_2.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  Web Development
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_2.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_3.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  Website Design
                                </Link>
                              </h3>
                              <p className="project-subtitle">Technology</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_3.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="project-box">
                          <div className="project-img">
                            <img
                              src="assets/img/project/project_3_4.jpg"
                              alt="project image"
                            />
                          </div>
                          <div
                            className="project-content"
                            data-bg-src="assets/img/bg/project_card_bg.png"
                          >
                            <div className="media-body">
                              <h3 className="box-title">
                                <Link to="/project-details">
                                  SEO Optimization
                                </Link>
                              </h3>
                              <p className="project-subtitle">Marketing</p>
                            </div>
                            <Link
                              to="assets/img/project/project_3_4.jpg"
                              className="icon-btn popup-image"
                            >
                              <i className="far fa-plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    data-slider-prev="#projectSlider3"
                    className="slider-arrow style3 slider-prev"
                  >
                    <i className="far fa-arrow-left" />
                  </button>
                  <button
                    data-slider-next="#projectSlider3"
                    className="slider-arrow style3 slider-next"
                  >
                    <i className="far fa-arrow-right" />
                  </button>
                </div>
              </div>
              <div className="shape-mockup" data-top="0%" data-right="0%">
                <img src="assets/img/shape/tech_shape_6.png" alt="shape" />
              </div>
              <div className="shape-mockup" data-bottom="0%" data-left="0%">
                <img src="assets/img/shape/tech_shape_7.png" alt="shape" />
              </div>
            </div>
          </section>
          <section className="space" id="process-sec">
            <div className="container">
              <div className="title-area text-center">
                <span className="sub-title">WORK PROCESS</span>
                <h2 className="sec-title">
                  How to work <span className="text-theme">it!</span>
                </h2>
              </div>
              <div className="process-card-area">
                <div className="process-line position-top">
                  <img src="assets/img/bg/process_line_2.svg" alt="line" />
                </div>
                <div className="row gy-40 justify-content-between">
                  <div className="col-sm-6 col-xl-auto process-card-wrap">
                    <div className="process-card">
                      <div className="pulse" />
                      <div className="process-card_icon">
                        <img src="assets/img/icon/process_box_1.png" alt="icon" />
                      </div>
                      <h2 className="box-title">Select a project</h2>
                      <p className="process-card_text">
                        Continua scale empowered metrics with cost effective
                        innovation.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-auto process-card-wrap">
                    <div className="process-card">
                      <div className="pulse" />
                      <div className="process-card_icon">
                        <img src="assets/img/icon/process_box_2.png" alt="icon" />
                      </div>
                      <h2 className="box-title">Project analysis</h2>
                      <p className="process-card_text">
                        Continua scale empowered metrics with cost effective
                        innovation.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-auto process-card-wrap">
                    <div className="process-card">
                      <div className="pulse" />
                      <div className="process-card_icon">
                        <img src="assets/img/icon/process_box_3.png" alt="icon" />
                      </div>
                      <h2 className="box-title">Plan Execute</h2>
                      <p className="process-card_text">
                        Continua scale empowered metrics with cost effective
                        innovation.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-auto process-card-wrap">
                    <div className="process-card">
                      <div className="pulse" />
                      <div className="process-card_icon">
                        <img src="assets/img/icon/process_box_4.png" alt="icon" />
                      </div>
                      <h2 className="box-title">Deliver result</h2>
                      <p className="process-card_text">
                        Continua scale empowered metrics with cost effective
                        innovation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div
              className="round-container"
              data-bg-src="assets/img/bg/testi_bg_4.jpg"
            >
              <div className="container">
                <div className="testi-block-area">
                  <div
                    className="swiper th-slider has-shadow testi-block-slide"
                    data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"1"},"1200":{"slidesPerView":"1"}}}'
                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="testi-block">
                          <p className="testi-block_text">
                            “Completely drive innovative value whereas
                            out-of-the-box paradigms. Interactively pursue
                            stand-alone markets after global results. Globally
                            plagiarize intermandated opportunities with.
                            Progressively leverage others multifunctional
                            methods.”
                          </p>
                          <div className="testi-block_profile">
                            <div className="testi-block_avater">
                              <img
                                src="assets/img/testimonial/testi_4_1.jpg"
                                alt="Avater"
                              />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title">Md Sumon Mia</h3>
                              <p className="testi-block_desig">CEO at Rimasu</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-block">
                          <p className="testi-block_text">
                            “Completely drive innovative value whereas
                            out-of-the-box paradigms. Interactively pursue
                            stand-alone markets after global results. Globally
                            plagiarize intermandated opportunities with.
                            Progressively leverage others multifunctional
                            methods.”
                          </p>
                          <div className="testi-block_profile">
                            <div className="testi-block_avater">
                              <img
                                src="assets/img/testimonial/testi_4_2.jpg"
                                alt="Avater"
                              />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title">David Farnandes</h3>
                              <p className="testi-block_desig">CEO at Anaton</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-block">
                          <p className="testi-block_text">
                            “Completely drive innovative value whereas
                            out-of-the-box paradigms. Interactively pursue
                            stand-alone markets after global results. Globally
                            plagiarize intermandated opportunities with.
                            Progressively leverage others multifunctional
                            methods.”
                          </p>
                          <div className="testi-block_profile">
                            <div className="testi-block_avater">
                              <img
                                src="assets/img/testimonial/testi_4_3.jpg"
                                alt="Avater"
                              />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title">Abraham Khalil</h3>
                              <p className="testi-block_desig">
                                CEO at Kormola
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="testi-block">
                          <p className="testi-block_text">
                            “Completely drive innovative value whereas
                            out-of-the-box paradigms. Interactively pursue
                            stand-alone markets after global results. Globally
                            plagiarize intermandated opportunities with.
                            Progressively leverage others multifunctional
                            methods.”
                          </p>
                          <div className="testi-block_profile">
                            <div className="testi-block_avater">
                              <img
                                src="assets/img/testimonial/testi_4_4.jpg"
                                alt="Avater"
                              />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title">Jackline Techie</h3>
                              <p className="testi-block_desig">
                                CEO at Anatora
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-pagination" />
                  </div>
                  <div className="testi-block-quote">
                    <img src="assets/img/icon/quote_3d.png" alt="quote" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="overflow-hidden space-top" id="faq-sec">
            <div className="container space-bottom">
              <div className="slider-area text-center">
                <div
                  className="swiper th-slider"
                  data-slider-options='{"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"},"1400":{"slidesPerView":"5"}}}'
                >
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_1.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_2.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_3.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_4.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_5.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_6.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_1.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_2.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_3.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_4.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_5.png" alt="Brand Logo" />
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="brand-box">
                        <img src="assets/img/brand/brand_1_6.png" alt="Brand Logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-9">
                  <div className="title-area text-center text-xl-start">
                    <span className="sub-title">FAQ'S</span>
                    <h2 className="sec-title">
                      Know More About Our It Solution
                    </h2>
                  </div>
                  <div className="accordion-area accordion" id="faqAccordion">
                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-1">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-1"
                          aria-expanded="false"
                          aria-controls="collapse-1"
                        >
                          Where can I get analytics help?
                        </button>
                      </div>
                      <div
                        id="collapse-1"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapse-item-1"
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body">
                          <p className="faq-text">
                            Dramatically disseminate real-time portals rather
                            than top-line action items. Uniquely provide access
                            to low-risk high-yield products without dynamic
                            products. Progressively re-engineer low-risk
                            high-yield ideas rather than emerging alignments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-card style3 active">
                      <div className="accordion-header" id="collapse-item-2">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-2"
                          aria-expanded="true"
                          aria-controls="collapse-2"
                        >
                          How long should a business plan be?
                        </button>
                      </div>
                      <div
                        id="collapse-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="collapse-item-2"
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body">
                          <p className="faq-text">
                            Dramatically disseminate real-time portals rather
                            than top-line action items. Uniquely provide access
                            to low-risk high-yield products without dynamic
                            products. Progressively re-engineer low-risk
                            high-yield ideas rather than emerging alignments.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-card style3">
                      <div className="accordion-header" id="collapse-item-3">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-3"
                          aria-expanded="false"
                          aria-controls="collapse-3"
                        >
                          Do I need a business plan?
                        </button>
                      </div>
                      <div
                        id="collapse-3"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapse-item-3"
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body">
                          <p className="faq-text">
                            Dramatically disseminate real-time portals rather
                            than top-line action items. Uniquely provide access
                            to low-risk high-yield products without dynamic
                            products. Progressively re-engineer low-risk
                            high-yield ideas rather than emerging alignments.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 mt-35 mt-xl-0">
                  <div className="img-box8">
                    <div className="img1">
                      <img src="assets/img/normal/faq_2_1.png" alt="Faq" />
                    </div>
                    <div className="shape1">
                      <img src="assets/img/normal/faq_2_2.png" alt="About" />
                    </div>
                    <div className="shape2">
                      <img src="assets/img/normal/faq_2_3.png" alt="About" />
                    </div>
                    <div className="color-animate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="space" id="blog-sec">
            <div className="container">
              <div className="title-area text-center">
                <span className="sub-title">NEWS &amp; ARTICLES</span>
                <h2 className="sec-title">Get Every Single Update Blog</h2>
              </div>
              <div className="slider-area">
                <div
                  className="swiper th-slider has-shadow"
                  id="blogSlider4"
                  data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
                >
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_5.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              15 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />2 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Unsatiable entreaties may collecting Power.
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_6.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              16 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />3 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Regional Manager &amp; limited time management.
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_7.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              17 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />2 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              What’s the Holding Back the It Solution Industry?
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_8.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              19 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />4 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Latin derived from Cicero's 1st-century BC
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_5.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              15 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />2 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Unsatiable entreaties may collecting Power.
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_6.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              16 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />3 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Regional Manager &amp; limited time management.
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_7.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              17 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />2 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              What’s the Holding Back the It Solution Industry?
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="blog-card">
                        <div className="blog-img">
                          <img src="assets/img/blog/blog_1_8.jpg" alt="blog image" />
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <Link to="/blog">
                              <i className="fal fa-calendar-days" />
                              19 Jan, 2024
                            </Link>
                            <Link to="/blog">
                              <i className="fal fa-comments" />4 Comments
                            </Link>
                          </div>
                          <h3 className="box-title">
                            <Link to="/blog-details">
                              Latin derived from Cicero's 1st-century BC
                            </Link>
                          </h3>
                          <div className="blog-bottom">
                            <Link to="/blog" className="author">
                              <img src="assets/img/blog/author-1-1.png" alt="avater" />
                              By Themeholy
                            </Link>
                            <Link to="/blog-details" className="line-btn">
                              Read More
                              <i className="fas fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  data-slider-prev="#blogSlider4"
                  className="slider-arrow style3 slider-prev"
                >
                  <i className="far fa-arrow-left" />
                </button>
                <button
                  data-slider-next="#blogSlider4"
                  className="slider-arrow style3 slider-next"
                >
                  <i className="far fa-arrow-right" />
                </button>
              </div>
            </div>
            <div className="shape-mockup" data-top="0%" data-right="0%">
              <img src="assets/img/bg/blog_bg_4.png" alt="shape" />
            </div>
          </section>
          <Footer1 />
          <div className="body-particle" id="body-particle" />
          <div className="scroll-top">
            <svg
              className="progress-circle svg-content"
              width="100%"
              height="100%"
              viewBox="-1 -1 102 102"
            >
              <path
                d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                style={{
                  transition: "stroke-dashoffset 10ms linear 0s",
                  strokeDasharray: "307.919, 307.919",
                  strokeDashoffset: "307.919",
                }}
              />
            </svg>
          </div>
    </div>
  );
};

export default SoftwareCompany;
