import React, { useState } from 'react'
 import TabTitle from '../Utiles/FunctionTitle';
 import { Link } from 'react-router-dom'

const Pricing = () => {
 TabTitle('Webteck - Technology & IT Solutions HTML Template - Pricing Plans')
  return (
<React.Fragment>
  <div
    className="breadcumb-wrapper"
    data-bg-src="assets/img/bg/breadcumb-bg.jpg"
  >
    <div className="container">
      <div className="breadcumb-content">
        <h1 className="breadcumb-title">Pricing Plans</h1>
        <ul className="breadcumb-menu">
          <li>
            <Link to="index">Home</Link>
          </li>
          <li>Pricing Plans</li>
        </ul>
      </div>
    </div>
  </div>
  <section className="space">
    <div className="container">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          OUR PRICING
        </span>
        <h2 className="sec-title">
          Popular Pricing <span className="text-theme fw-normal">Package</span>
        </h2>
      </div>
      <div className="row gy-4 justify-content-center">
        <div className="col-xl-4 col-md-6">
          <div className="price-card">
            <div className="price-card_top">
              <h3 className="price-card_title">Silver Package</h3>
              <p className="price-card_text">
                Pricing plan for IT Solution company
              </p>
              <h4 className="price-card_price">
                $199.00 <span className="duration">/Per Month</span>
              </h4>
              <div className="particle">
                <div className="price-particle" id="price-p1" />
              </div>
            </div>
            <div className="price-card_content">
              <div className="checklist">
                <ul>
                  <li>
                    <i className="fas fa-circle-check" /> 30 Days Trial Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Unlimited Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Multi-Language Content
                  </li>
                  <li className="unavailable">
                    <i className="fas fa-circle-check" /> Data backup and
                    recovery
                  </li>
                  <li className="unavailable">
                    <i className="fas fa-circle-check" /> Synced To Cloud
                    Database
                  </li>
                </ul>
              </div>
              <Link to="/pricing" className="th-btn">
                PURCHASE NOW
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="price-card">
            <div className="price-card_top">
              <h3 className="price-card_title">Gold Package</h3>
              <p className="price-card_text">
                Pricing plan for IT Solution company
              </p>
              <h4 className="price-card_price">
                $299.00 <span className="duration">/Per Month</span>
              </h4>
              <div className="particle">
                <div className="price-particle" id="price-p2" />
              </div>
            </div>
            <div className="price-card_content">
              <div className="checklist">
                <ul>
                  <li>
                    <i className="fas fa-circle-check" /> 30 Days Trial Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Unlimited Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Multi-Language Content
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Data backup and
                    recovery
                  </li>
                  <li className="unavailable">
                    <i className="fas fa-circle-check" /> Synced To Cloud
                    Database
                  </li>
                </ul>
              </div>
              <Link to="/pricing" className="th-btn">
                PURCHASE NOW
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="price-card">
            <div className="price-card_top">
              <h3 className="price-card_title">Platinum Package</h3>
              <p className="price-card_text">
                Pricing plan for IT Solution company
              </p>
              <h4 className="price-card_price">
                $599.00 <span className="duration">/Per Month</span>
              </h4>
              <div className="particle">
                <div className="price-particle" id="price-p3" />
              </div>
            </div>
            <div className="price-card_content">
              <div className="checklist">
                <ul>
                  <li>
                    <i className="fas fa-circle-check" /> 30 Days Trial Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Unlimited Features
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Multi-Language Content
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Data backup and
                    recovery
                  </li>
                  <li>
                    <i className="fas fa-circle-check" /> Synced To Cloud
                    Database
                  </li>
                </ul>
              </div>
              <Link to="/pricing" className="th-btn">
                PURCHASE NOW
                <i className="fa-regular fa-arrow-right ms-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape-mockup" data-top={0} data-right={0}>
      <img src="assets/img/shape/tech_shape_1.png" alt="shape" />
    </div>
    <div className="shape-mockup" data-top="0%" data-left="0%">
      <img src="assets/img/shape/square_1.png" alt="shape" />
    </div>
  </section>
  <section className="position-relative space">
    <div className="th-bg-img" data-bg-src="assets/img/bg/cta_bg_2.jpg">
      <img src="assets/img/bg/bg_overlay_1.png" alt="overlay" />
    </div>
    <div className="container z-index-common">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-7 col-md-9 text-center">
          <div className="title-area mb-35">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_2.svg"
                />
                <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
              </div>
              CONTACT US
            </span>
            <h2 className="sec-title text-white">
              Need Any Kind Of IT Solution For
              <span className="text-theme fw-normal">Your Business?</span>
            </h2>
          </div>
          <Link to="/contact" className="th-btn style3">
            Get In Touch
          </Link>
        </div>
      </div>
    </div>
  </section>
  <section className="bg-auto space" data-bg-src="assets/img/bg/testi_bg_2.png">
    <div className="container">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_2.svg"
            />
            <img src="assets/img/theme-img/title_shape_2.svg" alt="shape" />
          </div>
          CUSTOMER FEEDBACK
        </span>
        <h2 className="sec-title">
          What Happy Clients Says
          <br />
          <span className="text-theme fw-normal">About Us?</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="testiSlider2"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"1"},"992":{"slidesPerView":"1"},"1200":{"slidesPerView":"2"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-box_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-box_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-box_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_1.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">David Farnandes</h3>
                  <p className="testi-box_desig">CEO at Anaton</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_2.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Jackline Techie</h3>
                  <p className="testi-box_desig">CEO at Kormola</p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testi-box">
                <div className="testi-box_img">
                  <img
                    src="assets/img/testimonial/testi_2_3.jpg"
                    alt="Avater"
                  />
                  <div className="testi-box_quote">
                    <img src="assets/img/icon/quote_left_2.svg" alt="quote" />
                  </div>
                </div>
                <div className="testi-box_content">
                  <p className="testi-box_text">
                    Objectively visualize error-free technology for B2B
                    alignment. Monotonectally harness an expanded array of
                    models via effective collaboration.
                  </p>
                  <div className="testi-box_review">
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                    <i className="fa-solid fa-star-sharp" />
                  </div>
                  <h3 className="box-title">Abraham Khalil</h3>
                  <p className="testi-box_desig">CEO at Rimasu</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#testiSlider2"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>
        <button
          data-slider-next="#testiSlider2"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
    <div
      className="shape-mockup moving d-none d-xl-block"
      data-bottom="0%"
      data-left="10%"
    >
      <img src="assets/img/shape/line_1.png" alt="shape" />
    </div>
    <div
      className="shape-mockup jump d-none d-xl-block"
      data-top="20%"
      data-right="2%"
    >
      <img src="assets/img/shape/line_2.png" alt="shape" />
    </div>
  </section>
  <footer className="footer-wrapper footer-layout1">
    <div className="footer-top">
      <div className="logo-bg" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-3">
            <div className="footer-logo">
              <Link className="icon-masking" to="index">
                <span
                  data-mask-src="assets/img/logo-white.svg"
                  className="mask-icon"
                />
                <img src="assets/img/logo-white.svg" alt="Webteck" />
              </Link>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="footer-contact-wrap">
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-phone" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Quick Call Us:</span>
                  <Link to="tel:+19088000393" className="footer-contact_link">
                    +190-8800-0393
                  </Link>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-envelope" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Mail Us On:</span>
                  <Link
                    to="mailto:info@webteck.com"
                    className="footer-contact_link"
                  >
                    info@webteck.com
                  </Link>
                </div>
              </div>
              <div className="footer-contact">
                <div className="footer-contact_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <div className="media-body">
                  <span className="footer-contact_text">Visit Location:</span>
                  <Link
                    to="https://www.google.com/maps"
                    className="footer-contact_link"
                  >
                    54 Flemington, USA
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xxl-3 col-xl-4">
            <div className="widget footer-widget">
              <h3 className="widget_title">About Company</h3>
              <div className="th-widget-about">
                <p className="about-text">
                  Professionally redefine transparent ROI through low-risk
                  high-yield imperatives. Progressively create empowered. cost
                  effective users via team driven.
                </p>
                <div className="th-social">
                  <Link to="https://www.facebook.com/">
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link to="https://www.twitter.com/">
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="https://www.linkedin.com/">
                    <i className="fab fa-linkedin-in" />
                  </Link>
                  <Link to="https://www.whatsapp.com/">
                    <i className="fab fa-whatsapp" />
                  </Link>
                  <Link to="https://www.youtube.com/">
                    <i className="fab fa-youtube" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">Quick Links</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="../team">Meet Our Team</Link>
                  </li>
                  <li>
                    <Link to="/project">Our Projects</Link>
                  </li>
                  <li>
                    <Link to="../faq">Help &amp; FAQs</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget widget_nav_menu footer-widget">
              <h3 className="widget_title">IT SERVICES</h3>
              <div className="menu-all-pages-container">
                <ul className="menu">
                  <li>
                    <Link to="/service-details">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Business Development</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Product Management</Link>
                  </li>
                  <li>
                    <Link to="/service-details">UI/UX Design</Link>
                  </li>
                  <li>
                    <Link to="/service-details">Cloud services</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-auto">
            <div className="widget footer-widget">
              <h3 className="widget_title">Recent Posts</h3>
              <div className="recent-post-wrap">
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-1.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Unsatiable entreaties may collecting Power.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        21 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="recent-post">
                  <div className="media-img">
                    <Link to="/blog-details">
                      <img
                        src="assets/img/blog/recent-post-2-2.jpg"
                        alt="Blog Image"
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <h4 className="post-title">
                      <Link className="text-inherit" to="/blog-details">
                        Regional Manager limited time management.
                      </Link>
                    </h4>
                    <div className="recent-post-meta">
                      <Link to="/blog">
                        <i className="fal fa-calendar-days" />
                        22 June, 2024
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap bg-title">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <p className="copyright-text">
              Copyright <i className="fal fa-copyright" /> 2024
              <Link to="https://themeforest.net/user/themeholy">Themeholy</Link>.
              All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-end d-none d-lg-block">
            <div className="footer-links">
              <ul>
                <li>
                  <Link to="/about">Terms &amp; Condition</Link>
                </li>
                <li>
                  <Link to="/about">Careers</Link>
                </li>
                <li>
                  <Link to="/about">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="shape-left">
      <img src="assets/img/shape/footer_shape_2.svg" alt="shape" />
    </div>
    <div className="shape-right">
      <div className="particle-1" id="particle-5" />
    </div>
  </footer>
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  
</React.Fragment>
  )
}

export default Pricing