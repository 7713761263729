import React from 'react'
import TabTitle from '../Utiles/FunctionTitle'
import { Link } from 'react-router-dom'
import Footer1 from '../Common/Footer1'


const ITSolution = () => {
  TabTitle('IT Solutions - HRHK Technology')

  return (
    <div className="gr-bg5">
  <div className="th-hero-wrapper hero-5" id="hero">
    <div className="hero-inner">
      <div
        className="th-hero-bg"
        data-bg-src="assets/img/hero/hero_img_5_1.png"
      />
      <div className="container th-container5">
        <div className="hero-style5">
          <span className="sub-title style1 text-white">
            We’re Leading Startup Agency
          </span>
          <h1 className="hero-title text-white">
            Clear Thinking Makes Bright Future!
          </h1>
          <p className="hero-text text-white">
            Repurpose intuitive total linkage after timely mindshare. Credibly
            coonate reliable collaboration and idea-sharing after.
          </p>
          <div className="btn-group mt-35">
            <Link to="/service" className="th-btn style6 style-radius">
              View Services
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="counter-area-4 overflow-hidden space-bottom">
    <div className="container th-container5">
      <div className="counter-card4-wrap">
        <div className="counter-card4">
          <div className="counter-card4_icon">
            <img src="assets/img/icon/counter_card_4_1.svg" alt="" />
          </div>
          <div className="media-body">
            <h3 className="box-number">
              <span className="counter-number">20</span>X
            </h3>
            <h6 className="counter-title">Return on investment</h6>
            <p className="counter-text mb-0">
              Return on investment is a financial metric that measures
            </p>
          </div>
        </div>
        <div className="divider" />
        <div className="counter-card4">
          <div className="counter-card4_icon">
            <img src="assets/img/icon/counter_card_4_2.svg" alt="" />
          </div>
          <div className="media-body">
            <h3 className="box-number">
              <span className="counter-number">150</span>k+
            </h3>
            <h6 className="counter-title">Keywords optimization</h6>
            <p className="counter-text mb-0">
              keywords into online content, such as websites, blogs
            </p>
          </div>
        </div>
        <div className="divider" />
        <div className="counter-card4">
          <div className="counter-card4_icon">
            <img src="assets/img/icon/counter_card_4_3.svg" alt="" />
          </div>
          <div className="media-body">
            <h3 className="box-number">
              <span className="counter-number">10,000</span>+
            </h3>
            <h6 className="counter-title">Successful campaigns</h6>
            <p className="counter-text mb-0">
              Successful campaigns leverage strategic planning goals
            </p>
          </div>
        </div>
        <div className="divider" />
      </div>
    </div>
  </div>
  <div className="position-relative overflow-hidden" id="about-sec">
    <div className="container th-container5">
      <div
        className="about-sec4 position-relative overflow-hidden"
        data-bg-src="assets/img/normal/about_4.jpg"
      >
        <div className="about-shape4">
          <div className="marquee-wrapper">
            <div className="marquee">
              <div className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
              <div aria-hidden="true" className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
            </div>
            <div className="marquee marquee--reverse">
              <div className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
              <div aria-hidden="true" className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
            </div>
            <div className="marquee">
              <div className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
              <div aria-hidden="true" className="marquee-group">
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
                <div className="text">Adaptability</div>
                <div className="text">Creative Innovation</div>
                <div className="text">4000+ partners</div>
                <div className="text">15M Customer Worldwide</div>
                <div className="text">Engagement</div>
                <div className="text">Ethical Practices</div>
                <div className="text">Communication</div>
                <div className="text">Personalization</div>
                <div className="text">Expertise</div>
                <div className="text">50+ Countries</div>
                <div className="text">Expertise</div>
                <div className="text">Innovation</div>
                <div className="text">Inclusivity</div>
                <div className="text">Diversity</div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area4">
          <div className="title-area-wrapper">
            <div className="title-area mb-40">
              <span className="sub-title style1 text-white">
                Our Main Mission
              </span>
              <h2 className="sec-title text-white">
                Our mission is to look after the financial best interests with
                automation
              </h2>
            </div>
            <Link to="/about" className="th-btn style5 style-radius">
              More About Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="service-area6 space" id="service-sec">
    <div className="container th-container4">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_1.svg"
            />
            <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
          </div>
          What We Provide
        </span>
        <h2 className="sec-title">
          Data-driven, customer-centric digital services
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="serviceSlider1"
          data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_1.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Web Marketing</Link>
                  </h3>
                  <p className="service-item_text">
                    Web marketing, also known as online marketing or digital
                    marketing encompasses
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_2.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Web Development</Link>
                  </h3>
                  <p className="service-item_text">
                    Web development involves creating and maintaining websites
                    or web applications
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_3.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Creative Layout</Link>
                  </h3>
                  <p className="service-item_text">
                    A creative layout refers to the visually appealing and
                    strategically organized arrangement
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_4.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Product Design</Link>
                  </h3>
                  <p className="service-item_text">
                    Product design is the process of creating and refining a
                    tangible or digital product
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_1.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Web Marketing</Link>
                  </h3>
                  <p className="service-item_text">
                    Web marketing, also known as online marketing or digital
                    marketing encompasses
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_2.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Web Development</Link>
                  </h3>
                  <p className="service-item_text">
                    Web development involves creating and maintaining websites
                    or web applications
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_3.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Creative Layout</Link>
                  </h3>
                  <p className="service-item_text">
                    A creative layout refers to the visually appealing and
                    strategically organized arrangement
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="service-item th-ani">
                <div className="service-item_icon">
                  <img src="assets/img/icon/service_6_4.svg" alt="Icon" />
                </div>
                <div className="service-item_content">
                  <h3 className="box-title">
                    <Link to="/service-details">Product Design</Link>
                  </h3>
                  <p className="service-item_text">
                    Product design is the process of creating and refining a
                    tangible or digital product
                  </p>
                  <Link to="/service-details" className="line-btn">
                    Read Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#serviceSlider1"
          className="slider-arrow slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>
        <button
          data-slider-next="#serviceSlider1"
          className="slider-arrow slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
  </section>
  <div
    className="overflow-hidden space"
    data-bg-src="assets/img/bg/choose_bg_2.jpg"
  >
    <div className="container th-container5">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div className="title-area text-center">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_1.svg"
                />
                <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
              </div>
              Why Work With Us
            </span>
            <h2 className="sec-title text-white">
              Why people are very loved to working with us?
            </h2>
          </div>
        </div>
      </div>
      <div className="choose-tabs-wrapper">
        <div
          className="nav nav-tabs choose-tabs-tabs"
          id="nav-tab"
          role="tablist"
        >
          <button
            className="nav-link active"
            id="nav-step1-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-step1"
            type="button"
          >
            Expertise and Experience
          </button>
          <button
            className="nav-link"
            id="nav-step2-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-step2"
            type="button"
          >
            Quality Workmanship
          </button>
          <button
            className="nav-link"
            id="nav-step3-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-step3"
            type="button"
          >
            Commitment to Safety
          </button>
          <button
            className="nav-link"
            id="nav-step4-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-step4"
            type="button"
          >
            Reliability and Consistency
          </button>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-step1"
            role="tabpanel"
          >
            <div className="choose-wrapper">
              <div className="choose-content">
                <div className="title-area mb-30">
                  <h5 className="sec-title mb-3 text-white">
                    Expertise and Experience
                  </h5>
                  <p className="box-text">
                    While Google Ads is undoubtedly a powerful advertising
                    platform, it’s important to recognize that there are various
                    other avenues to effectively display ads...
                  </p>
                </div>
                <div className="checklist">
                  <ul>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Best Service for You
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Keeping Your Team Productive
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Predictable Costs 24/
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="th-btn style6 style-radius">
                  Get Started
                </Link>
              </div>
              <div className="choose-image th-anim">
                <img src="assets/img/normal/choose_img_1.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="nav-step2" role="tabpanel">
            <div className="choose-wrapper">
              <div className="choose-content">
                <div className="title-area mb-30">
                  <h5 className="sec-title mb-3 text-white">
                    Quality Workmanship
                  </h5>
                  <p className="box-text">
                    While Google Ads is undoubtedly a powerful advertising
                    platform, it’s important to recognize that there are various
                    other avenues to effectively display ads...
                  </p>
                </div>
                <div className="checklist">
                  <ul>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Best Service for You
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Keeping Your Team Productive
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Predictable Costs 24/
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="th-btn style6 style-radius">
                  Get Started
                </Link>
              </div>
              <div className="choose-image th-anim">
                <img src="assets/img/normal/choose_img_2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="nav-step3" role="tabpanel">
            <div className="choose-wrapper">
              <div className="choose-content">
                <div className="title-area mb-30">
                  <h5 className="sec-title mb-3 text-white">
                    Commitment to Safety
                  </h5>
                  <p className="box-text">
                    While Google Ads is undoubtedly a powerful advertising
                    platform, it’s important to recognize that there are various
                    other avenues to effectively display ads...
                  </p>
                </div>
                <div className="checklist">
                  <ul>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Best Service for You
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Keeping Your Team Productive
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Predictable Costs 24/
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="th-btn style6 style-radius">
                  Get Started
                </Link>
              </div>
              <div className="choose-image th-anim">
                <img src="assets/img/normal/choose_img_3.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="nav-step4" role="tabpanel">
            <div className="choose-wrapper">
              <div className="choose-content">
                <div className="title-area mb-30">
                  <h5 className="sec-title mb-3 text-white">
                    Reliability and Consistency
                  </h5>
                  <p className="box-text">
                    While Google Ads is undoubtedly a powerful advertising
                    platform, it’s important to recognize that there are various
                    other avenues to effectively display ads...
                  </p>
                </div>
                <div className="checklist">
                  <ul>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Best Service for You
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Keeping Your Team Productive
                    </li>
                    <li>
                      <i className="far fa-check-circle text-body" />
                      Predictable Costs 24/
                    </li>
                  </ul>
                </div>
                <Link to="/contact" className="th-btn style6 style-radius">
                  Get Started
                </Link>
              </div>
              <div className="choose-image th-anim">
                <img src="assets/img/normal/choose_img_4.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="overflow-hidden bg-white space" id="project-sec">
    <div className="container th-container5">
      <div className="row">
        <div className="col-xl-4">
          <div className="title-area mb-50 text-center text-md-start">
            <span className="sub-title">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_1.svg"
                />
                <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
              </div>
              Success Stories
            </span>
            <h2 className="sec-title">
              Our work that inspired you to work with us
            </h2>
          </div>
          <div className="project-btn">
            <Link to="/contact" className="th-btn style-border style-radius">
              View All Stories
            </Link>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="slider-area project-slider4">
            <div
              className="swiper th-slider has-shadow"
              id="projectSlider1"
              data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"2"}}}'
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="project-box4">
                    <div className="project-img">
                      <img
                        src="assets/img/project/project_4_1.jpg"
                        alt="project"
                      />
                    </div>
                    <div className="project-content">
                      <div className="media-body">
                        <h3 className="box-title">
                          <Link to="/project-details">Technology Growth</Link>
                        </h3>
                        <div className="project-tags">
                          <Link to="/project-details">Marketing</Link>
                          <Link to="/project-details">Service</Link>
                          <Link to="/project-details">Solution</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="project-box4">
                    <div className="project-img">
                      <img
                        src="assets/img/project/project_4_2.jpg"
                        alt="project"
                      />
                    </div>
                    <div className="project-content">
                      <div className="media-body">
                        <h3 className="box-title">
                          <Link to="/project-details">IT Consultency</Link>
                        </h3>
                        <div className="project-tags">
                          <Link to="/project-details">Marketing</Link>
                          <Link to="/project-details">Service</Link>
                          <Link to="/project-details">Solution</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="project-box4">
                    <div className="project-img">
                      <img
                        src="assets/img/project/project_4_1.jpg"
                        alt="project"
                      />
                    </div>
                    <div className="project-content">
                      <div className="media-body">
                        <h3 className="box-title">
                          <Link to="/project-details">Technology Growth</Link>
                        </h3>
                        <div className="project-tags">
                          <Link to="/project-details">Marketing</Link>
                          <Link to="/project-details">Service</Link>
                          <Link to="/project-details">Solution</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="project-box4">
                    <div className="project-img">
                      <img
                        src="assets/img/project/project_4_2.jpg"
                        alt="project"
                      />
                    </div>
                    <div className="project-content">
                      <div className="media-body">
                        <h3 className="box-title">
                          <Link to="/project-details">IT Consultency</Link>
                        </h3>
                        <div className="project-tags">
                          <Link to="/project-details">Marketing</Link>
                          <Link to="/project-details">Service</Link>
                          <Link to="/project-details">Solution</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="brand-area bg-white space-bottom">
    <div className="container th-container5">
      <div className="title-area mb-30 text-center">
        <h6 className="brand-title">
          We’ve worked on over 200 projects with 150+ clients
        </h6>
      </div>
      <div
        className="swiper th-slider"
        id="brandSlider1"
        data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"},"1400":{"slidesPerView":"5"}}}'
      >
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_1.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_2.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_3.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_4.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_5.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_1.png" alt="Brand Logo" />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="brand-box1-1">
              <img src="assets/img/brand/brand_1_2.png" alt="Brand Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="bg-white space-bottom">
    <div className="container th-container5">
      <div
        className="cta-area4 text-center bg-theme"
        data-bg-src="assets/img/bg/cta_bg_3.jpg"
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="title-area mb-25 text-center">
              <span className="sub-title cta-title">Work With Us</span>
              <h2 className="sec-title text-white">
                Ready to revolutionize your marketing?
              </h2>
              <p className="cta-text text-white">
                Sign up for HRHK today and experience the power of AI-powered
                copywriting.
              </p>
            </div>
            <div className="cta-counter">
              <h2 className="counter-title text-white">
                <span className="counter-number">10,000000</span>M+
              </h2>
              <p className="cta-desc text-white text-uppercase">Ads We Run</p>
            </div>
            <div className="mt-45">
              <Link to="/about" className="th-btn style5 style-radius">
                More About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="space">
    <div className="container th-container5">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_1.svg"
            />
            <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
          </div>
          Pricing Plans
        </span>
        <h2 className="sec-title">Effective Pricing Plans</h2>
      </div>
      <div className="row gy-4 justify-content-center">
        <div className="col-xl-4 col-md-6">
          <div className="price-box th-ani">
            <h3 className="box-title">Normal Plan</h3>
            <span className="offer-tag" />
            <h4 className="price-box_price">
              <span className="currency">$</span>39.00
            </h4>
            <h6 className="price-box_text">Get Popular Plan From Us</h6>
            <div className="price-box_content">
              <div className="available-list">
                <ul>
                  <li>Ad Management</li>
                  <li>Multi-Language Content</li>
                  <li>Conversational Bots</li>
                </ul>
              </div>
              <Link to="/contact" className="th-btn btn-fw style-radius">
                Choose Plan
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="price-box th-ani active">
            <h3 className="box-title">Standard Plan</h3>
            <span className="offer-tag">
              <span className="tag">Featured</span>
            </span>
            <h4 className="price-box_price">
              <span className="currency">$</span>69.00
            </h4>
            <h6 className="price-box_text">Get Popular Plan From Us</h6>
            <div className="price-box_content">
              <div className="available-list">
                <ul>
                  <li>Ad Management</li>
                  <li>Live Chat</li>
                  <li>Multi-Language Content</li>
                  <li>Conversational Bots</li>
                  <li>Programmable Chatbots</li>
                </ul>
              </div>
              <Link to="/contact" className="th-btn btn-fw style-radius">
                Choose Plan
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="price-box th-ani">
            <h3 className="box-title">Ultimate Plan</h3>
            <span className="offer-tag" />
            <h4 className="price-box_price">
              <span className="currency">$</span>49.00
            </h4>
            <h6 className="price-box_text">Get Popular Plan From Us</h6>
            <div className="price-box_content">
              <div className="available-list">
                <ul>
                  <li>Ad Management</li>
                  <li>Multi-Language Content</li>
                  <li>Conversational Bots</li>
                </ul>
              </div>
              <Link to="/contact" className="th-btn btn-fw style-radius">
                Choose Plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="cta-area5" data-bg-src="assets/img/bg/cta_bg_4.jpg">
    <div className="container th-container5">
      <div className="row">
        <div className="col-xl-6">
          <div className="cta-title-area">
            <div className="title-area mb-50">
              <h2 className="sec-title text-white">
                Get to know the incredible individuals behind our company
              </h2>
              <p className="sec-text text-white">
                Comprehensive financial consulting services tailored to your
                needs
              </p>
            </div>
            <div className="">
              <Link to="/contact" className="th-btn style6 style-radius">
                Schedule a free consultation
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="space" data-bg-src="assets/img/bg/appointment_bg_2.jpg">
    <div className="container th-container5">
      <div className="row align-items-center">
        <div className="col-xl-6">
          <div className="title-area mb-30 text-xl-start">
            <span className="sub-title style1">
              <div className="icon-masking me-2">
                <span
                  className="mask-icon"
                  data-mask-src="assets/img/theme-img/title_shape_1.svg"
                />
                <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
              </div>
              Work With Us
            </span>
            <h2 className="sec-title text-white">
              Get free IT business consultation today. Feel free to contact!
            </h2>
            <p className="sec-text">
              We’re happy to answer any questions you may have and help you
              determine which of our services best fit your needs.
            </p>
          </div>
          <div className="feature-wrapper style3">
            <div className="feature-icon">
              <Link to="tel:+18003568933">
                <img src="assets/img/icon/call_2.svg" alt="" />
              </Link>
            </div>
            <div className="media-body">
              <span className="header-info_label">Call us at:</span>
              <p className="header-info_link">
                <Link to="tel:+91 8448504477">+91 8448504477</Link>
              </p>
            </div>
          </div>
          <div className="work-area">
            <h5 className="box-title mb-30 text-white">How We Works?</h5>
            <div className="work-item_wrapper">
              <div className="work-item">
                <span className="work-item_number">01</span>
                <p className="work-text">
                  We schedule a call at for your service
                </p>
              </div>
              <div className="work-item">
                <span className="work-item_number">02</span>
                <p className="work-text">
                  Engage with clients understand their needs, and goals.
                </p>
              </div>
              <div className="work-item">
                <span className="work-item_number">03</span>
                <p className="work-text">
                  Gather feedback from clients to understand their satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="appointment-area-wrapp">
            <h6 className="title">Please fill in the information below</h6>
            <form
              action="https://html.themeholy.com/webteck/demo/mail.php"
              method="POST"
              className="appointment-form3 input-smoke ajax-contact"
            >
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Email address*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Phone number*</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="number"
                    id="number"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Company website*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    id="text"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-12">
                  <label htmlFor="name">Company / Organization about*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="text"
                    id="text2"
                    placeholder=""
                  />
                </div>
                <div className="form-group col-12">
                  <label htmlFor="name">Describe your message*</label>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={3}
                    className="form-control"
                    placeholder=""
                    defaultValue={""}
                  />
                </div>
                <div className="form-btn col-12">
                  <button className="th-btn btn-fw">Submit Message</button>
                </div>
              </div>
              <p className="form-messages mb-0 mt-3" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="bg-top-right overflow-hidden space"
    id="blog-sec"
    data-bg-src="assets/img/bg/blog_bg_1.png"
  >
    <div className="container">
      <div className="title-area text-center">
        <span className="sub-title">
          <div className="icon-masking me-2">
            <span
              className="mask-icon"
              data-mask-src="assets/img/theme-img/title_shape_1.svg"
            />
            <img src="assets/img/theme-img/title_shape_1.svg" alt="shape" />
          </div>
          NEWS &amp; ARTICLES
        </span>
        <h2 className="sec-title">
          Get Every Single Update <span className="text-theme">Blog</span>
        </h2>
      </div>
      <div className="slider-area">
        <div
          className="swiper th-slider has-shadow"
          id="blogSlider1"
          data-slider-options='{"loop":true,"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      15 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Unsatiable entreaties may collecting Power.
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      16 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />3 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Regional Manager &amp; limited time management.
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      17 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      What’s the Holding Back the It Solution Industry?
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      19 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />4 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Latin derived from Cicero's 1st-century BC
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_1.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      15 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Unsatiable entreaties may collecting Power.
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_2.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      16 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />3 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Regional Manager &amp; limited time management.
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_3.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      17 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />2 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      What’s the Holding Back the It Solution Industry?
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="blog-card">
                <div className="blog-img">
                  <img src="assets/img/blog/blog_1_4.jpg" alt="blog image" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fal fa-calendar-days" />
                      19 Jan, 2024
                    </Link>
                    <Link to="/blog">
                      <i className="fal fa-comments" />4 Comments
                    </Link>
                  </div>
                  <h3 className="box-title">
                    <Link to="/blog-details">
                      Latin derived from Cicero's 1st-century BC
                    </Link>
                  </h3>
                  <div className="blog-bottom">
                    <Link to="/blog" className="author">
                      <img src="assets/img/blog/author-1-1.png" alt="avater" />
                      By Themeholy
                    </Link>
                    <Link to="/blog-details" className="line-btn">
                      Read More
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          data-slider-prev="#blogSlider1"
          className="slider-arrow style3 slider-prev"
        >
          <i className="far fa-arrow-left" />
        </button>
        <button
          data-slider-next="#blogSlider1"
          className="slider-arrow style3 slider-next"
        >
          <i className="far fa-arrow-right" />
        </button>
      </div>
    </div>
    <div className="shape-mockup" data-bottom={0} data-left={0}>
      <div className="particle-2 small" id="particle-4" />
    </div>
  </section>
  <Footer1/>
  <div className="scroll-top">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path
        d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
        style={{
          transition: "stroke-dashoffset 10ms linear 0s",
          strokeDasharray: "307.919, 307.919",
          strokeDashoffset: "307.919"
        }}
      />
    </svg>
  </div>
  
</div>

  )
}

export default ITSolution