import React, {useEffect} from 'react';
import DigitalAgency from './components/Multipage/DigitalAgency';
import WebDevelopment from './components/Multipage/WebDevelopment';
import SoftwareCompany from './components/Multipage/SoftwareCompany';
import SoftwareCompany2 from './components/Multipage/Sofware-Company-2';
import Startup from './components/Multipage/Startup';
import ITSolution from './components/Multipage/ITSolution';
import WebAgency from './components/Multipage/WebAgency';
import HomeStartup from './components/Multipage/HomeStartup';
import GameSolution from './components/Multipage/GameSolution';
import SassMarketing from './components/Multipage/SassMarketing';
import SassLanding from './components/Multipage/SassLanding';
import SassLanding2 from './components/Multipage/Sass-Landing-2';
import AppLanding from './components/Multipage/AppLanding';
import AITechnology from './components/Multipage/AITechnology';
import CyberSecurity from './components/Multipage/CyberSecurity';
import CyberSecurity2 from './components/Multipage/Cyber-Security-2';
import ITCompany from './components/Multipage/ITCompany';
import DigitalMarking from './components/Multipage/DigitalMarking';
import ITAgency from './components/Multipage/ITAgency';
import CRM from './components/Multipage/CRM';
import ITConsulting from './components/Multipage/ITConsulting';
import HelpDesk from './components/Multipage/HelpDesk';
import { createBrowserRouter, RouterProvider, Routes, BrowserRouter, Route } from 'react-router-dom';
import AITechnologyOp from './components/Onepage/AI-Technology-Op';
import DigitalAgencyOp from './components/Onepage/Digital-Agency-Op';
import WebDevelopmentOp from './components/Onepage/Web-Development-Op';
import SoftwareCompanyOp from './components/Onepage/Software-Company-Op';
import SoftwareCompanyOp2 from './components/Onepage/Software-Company-Op-2';
import StartupCompanyOp from './components/Onepage/Startup-Company-Op';
import ITSolutionOp from './components/Onepage/IT-Solution-Op';
import WebAgencyOp from './components/Onepage/Web-Agency-Op';
import HomeStartupOp from './components/Onepage/Home-Startup-Op';
import GameSolutionOp from './components/Onepage/Game-Solution-Op';
import SassMarketingOp from './components/Onepage/Sass-Marketing-Op';
import SassLandingOp from './components/Onepage/Sass-Landing-Op';
import SassLandingOp2 from './components/Onepage/Sass-Landing-Op-2';
import AppLandingOp from './components/Onepage/App-Landing-Op';
import CyberSecurityOp from './components/Onepage/Cyber-Security-Op';
import CyberSecurityOp2 from './components/Onepage/Cyber-Security-Op-2';
import ITCompanyOp from './components/Onepage/IT-Company-Op';
import DigitalMarkingOp from './components/Onepage/Digital-Marking-Op';
import ITAgencyOp from './components/Onepage/IT-Agency-Op';
import CRMOp from './components/Onepage/CRM-Op';
import ITConsultingOp from './components/Onepage/IT-Consulting-Op';
import HelpDeskOp from './components/Onepage/Help-Desk-Op';
import Blog from './components/Blog/Blog';
import BlogDetails from './components/Blog/Blog-Details';
import Shop from './components/Pages/Shop/Shop';
import ShopDetails from './components/Pages/Shop/Shop-Details';
import Cart from './components/Pages/Shop/Cart';
import Checkout from './components/Pages/Shop/Checkout';
import Wishlist from './components/Pages/Shop/Wishlist';
import Team from './components/Pages/Team';
import TeamDetails from './components/Pages/Team-Details';
import Project from './components/Pages/Project';
import ProjectDetails from './components/Pages/Project-Details';
import Gallery from './components/Pages/Gallery';
import Pricing from './components/Pages/Pricing';
import FAQ from './components/Pages/FAQ';
import Error from './components/Pages/Error';
import Service from './components/Services/Service';
import ServiceDetails from './components/Services/Service-Details';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Navbar from './components/Common/Navbar';

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.type = "text/jsx";
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.head.appendChild(script);
  });
};

const loadMultipleScripts = (srcArray) => {
  return Promise.all(srcArray.map(src => loadScript(src)));
};

function App() {

   const router = createBrowserRouter([
     {
       path: "/",
       element: <DigitalAgency />,
     },
     {
      path: "/home-web-development",
      element: <WebDevelopment />,
    },
    {
      path: "/home-software-company",
      element: <SoftwareCompany />,
    },
    // {
    //   path: "/home-software-company-2",
    //   element: <SoftwareCompany2 />,
    // },
    // {
    //   path: "/home-startup-company",
    //   element: <Startup />,
    // },
    {
      path: "/home-it-solution",
      element: <ITSolution />,
    },
    // {
    //   path: "/home-web-agency",
    //   element: <WebAgency />,
    // },
    // {
    //   path: "/home-startup",
    //   element: <HomeStartup />,
    // },
    // {
    //   path: "/home-game-solution",
    //   element: <GameSolution />,
    // },
    // {
    //   path: "/home-sass-marketing",
    //   element: <SassMarketing />,
    // },
    // {
    //   path: "/home-sass-landing",
    //   element: <SassLanding />,
    // },
    // {
    //   path: "/home-sass-landing-2",
    //   element: <SassLanding2 />,
    // },
    {
      path: "/home-app-landing",
      element: <AppLanding />,
    },
  //   {
  //     path: "/home-ai-technology",
  //     element: <AITechnology />,
  //   },
  //   {
  //     path: "/home-cyber-security",
  //     element: <CyberSecurity />,
  //   },
  //   {
  //     path: "/home-cyber-security-2",
  //     element: <CyberSecurity2 />,
  //   },
  //   {
  //     path: "/home-it-company",
  //     element: <ITCompany />,
  //   },
  //   {
  //     path: "/home-digital-marking",
  //     element: <DigitalMarking />,
  //   },
  //   {
  //     path: "/home-it-agency",
  //     element: <ITAgency />,
  //   },
  //   {
  //     path: "/home-crm",
  //     element: <CRM />,
  //   },
  //   {
  //     path: "/home-it-consulting",
  //     element: <ITConsulting />,
  //   },
  //   {
  //     path: "/home-help-desk",
  //     element: <HelpDesk />,
  //   },
  //   {
  //    path: "/home-digital-agency-op",
  //    element: <DigitalAgencyOp />,
  //  },
  //  {
  //    path: "/home-web-development-op",
  //    element: <WebDevelopmentOp />,
  //  },
  //  {
  //    path: "/home-software-company-op",
  //    element: <SoftwareCompanyOp />,
  //  },
  //  {
  //    path: "/home-software-company-2-op",
  //    element: <SoftwareCompanyOp2 />,
  //  },
  //  {
  //    path: "/home-startup-company-op",
  //    element: <StartupCompanyOp />,
  //  },
  //  {
  //    path: "/home-it-solution-op",
  //    element: <ITSolutionOp />,
  //  },
  //  {
  //    path: "/home-web-agency-op",
  //    element: <WebAgencyOp />,
  //  },
  //  {
  //    path: "/home-startup-op",
  //    element: <HomeStartupOp />,
  //  },
  //  {
  //    path: "/home-game-solution-op",
  //    element: <GameSolutionOp />,
  //  },
  //  {
  //    path: "/home-sass-marketing-op",
  //    element: <SassMarketingOp />,
  //  },
  //  {
  //    path: "/home-sass-landing-op",
  //    element: <SassLandingOp />,
  //  },
  //  {
  //    path: "/home-sass-landing-2-op",
  //    element: <SassLandingOp2 />,
  //  },
  //  {
  //    path: "/home-app-landing-op",
  //    element: <AppLandingOp />,
  //  },
  //  {
  //    path: "/home-ai-technology-op",
  //    element: <AITechnologyOp />,
  //  },
  //  {
  //    path: "/home-cyber-security-op",
  //    element: <CyberSecurityOp />,
  //  },
  //  {
  //    path: "/home-cyber-security-2-op",
  //    element: <CyberSecurityOp2 />,
  //  },
  //  {
  //    path: "/home-it-company-op",
  //    element: <ITCompanyOp />,
  //  },
  //  {
  //    path: "/home-digital-marking-op",
  //    element: <DigitalMarkingOp />,
  //  },
  //  {
  //    path: "/home-it-agency-op",
  //    element: <ITAgencyOp />,
  //  },
  //  {
  //    path: "/home-crm-op",
  //    element: <CRMOp />,
  //  },
  //  {
  //    path: "/home-it-consulting-op",
  //    element: <ITConsultingOp />,
  //  },
  //  {
  //    path: "/home-help-desk-op",
  //    element: <HelpDeskOp />,
  //  },
   {
     path: "blog",
     element: <Blog />,
   },
  //  {
  //    path: "blog-details",
  //    element: <BlogDetails />,
  //  },
  //  {
  //    path: "shop",
  //    element: <Shop />,
  //  },
  //  {
  //    path: "shop-details",
  //    element: <ShopDetails />,
  //  },
  //  {
  //    path: "cart",
  //    element: <Cart />,
  //  },
  //  {
  //    path: "checkout",
  //    element: <Checkout />,
  //  },
  //  {
  //    path: "wishlist",
  //    element: <Wishlist />,
  //  },
  //  {
  //    path: "team",
  //    element: <Team />,
  //  },
  //  {
  //    path: "team-details",
  //    element: <TeamDetails />,
  //  },
  //  {
  //    path: "project",
  //    element: <Project />,
  //  },
  //  {
  //    path: "project-details",
  //    element: <ProjectDetails />,
  //  },
  //  {
  //    path: "gallery",
  //    element: <Gallery />,
  //  },
  //  {
  //    path: "pricing",
  //    element: <Pricing />,
  //  },
  //  {
  //    path: "faq",
  //    element: <FAQ />,
  //  },
  //  {
  //    path: "error",
  //    element: <Error />,
  //  },
  //  {
  //    path: "service",
  //    element: <Service />,
  //  },
   {
     path: "service-details",
     element: <ServiceDetails />,
   },
   {
     path: "about",
     element: <AboutUs />,
   },
   {
     path: "contact",
     element: <Contact />,
   },
     
   ]);

  useEffect(() => {
     // List of scripts to load
    const scriptUrls = [
      '%PUBLIC_URL%/assets/js/vendor/jquery-3.7.1.min.js', // Replace with actual paths
      '%PUBLIC_URL%/assets/js/swiper-bundle.min.js',
      '%PUBLIC_URL%/assets/js/bootstrap.min.js',
      '%PUBLIC_URL%/assets/js/jquery.magnific-popup.min.js',
      '%PUBLIC_URL%/assets/js/jquery.counterup.min.js',
      '%PUBLIC_URL%/assets/js/circle-progress.js',
      '%PUBLIC_URL%/assets/js/jquery-ui.min.js',
      '%PUBLIC_URL%/assets/js/imagesloaded.pkgd.min.js',
      '%PUBLIC_URL%/assets/js/isotope.pkgd.min.js',
      '%PUBLIC_URL%/assets/js/tilt.jquery.min.js',
      '%PUBLIC_URL%/assets/js/gsap.min.js',
      '%PUBLIC_URL%/assets/js/ScrollTrigger.min.js',
      '%PUBLIC_URL%/assets/js/smooth-scroll.js',
      '%PUBLIC_URL%/assets/js/particles.min.js',
      '%PUBLIC_URL%/assets/js/particles-config.js',
      '%PUBLIC_URL%/assets/js/main.js'
    ];

    // Load all scripts
    loadMultipleScripts(scriptUrls)
      .then((scripts) => {
        console.log('All initial scripts loaded:', scripts);
      })
      .catch((error) => {
        console.error('Error loading scripts:', error);
      });

    return () => {
      scriptUrls.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, []);

  return (
  <>
    
      {/* <RouterProvider router={router} />   */}
     <BrowserRouter>
      <Navbar/> 
     <Routes>
      <Route path="/" element={<DigitalAgency />} ></Route>
      <Route path="/home-web-development" element={<WebDevelopment />} ></Route>
      <Route path="/home-software-company" element={<SoftwareCompany />} ></Route>
      <Route path="/home-it-solution" element={<ITSolution />} ></Route>
      <Route path="/home-app-landing" element={<AppLanding />} ></Route>
      <Route path="/about" element={<AboutUs />} ></Route>
      <Route path="/contact" element={<Contact />} ></Route>
      <Route path="/blog" element={<Blog />} ></Route>
      <Route path="/blog-details" element={<BlogDetails />} ></Route>
      <Route path="/service-details" element={<ServiceDetails />} ></Route>
      <Route path="/service" element={<Service />} ></Route>
      <Route path="/project-details" element={<ProjectDetails />} ></Route>
      <Route path="/project" element={<Project />} ></Route>
      <Route path="/team-details" element={<TeamDetails />} ></Route>
      <Route path="/pricing" element={<Pricing />} ></Route>


    </Routes>
     </BrowserRouter> 
  </>

  

  );
}

export default App;
